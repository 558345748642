import React, { useContext } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import { Link as RouterLink } from "react-router-dom";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import DoneIcon from "@material-ui/icons/Done";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PriceIcon from "../../images/price.svg";
import {
  CardWraper,
  CardContent,
  Divider,
  ArrowComponent,
  Time,
} from "./styles";
import { useSnackbar } from "notistack";
import { HttpContext } from "../../context/HttpContext";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  cover: {
    width: 397,
    minWidth: 397,
    height: 222,
    [theme.breakpoints.down("sm")]: {
      minWidth: "auto",
      width: "auto",
      marginBottom: "20px",
    },
  },
  testSmall: {
    fontSize: "14px",
  },
  title: {
    fontSize: "32px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
    },
  },
}));

function EventDataView({ event, updateEvent }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { authAxios } = useContext(HttpContext);
  const initState = {
    status: "idle",
    clicked: "",
    error: null,
    data: null,
  };
  const [state, setState] = React.useState(initState);
  // const theme = useTheme()

  const { status, clicked } = state;

  const {
    id,
    title,
    timings,
    location,
    price,
    coverPhoto,
    shortAbout,
    startAt,
    invitation,
    canInviteGuests,
  } = event;

  function handleConfirm() {
    setState({
      ...state,
      status: "pending",
      clicked: "confirm",
    });
    authAxios({
      url: `event/table/invitations/${invitation.id}/confirm`,
      method: "POST",
    })
      .then((res) => {
        setState({
          ...state,
          data: res.data,
          status: "resolved",
          clicked: "",
        });
        const invitation = res.data;
        let canInviteGuests = false;
        if (invitation.type?.label === "Host") {
          canInviteGuests = true;
        }
        // setEvent({ ...event, invitation });
        updateEvent({ ...event, invitation, canInviteGuests }, "confirm");
        enqueueSnackbar("You successfully confirmed the invitation", {
          variant: "success",
        });
      })
      .catch((onError) => {
        console.log(onError);
        setState({
          ...state,
          error: "Something went wrong",
          status: "rejected",
          clicked: "",
        });
        enqueueSnackbar("Something went wrong", { variant: "error" });
      });
  }

  function handleDecline() {
    setState({
      ...state,
      status: "pending",
      clicked: "decline",
    });
    authAxios({
      url: `event/table/invitations/${invitation.id}/decline`,
      method: "POST",
    })
      .then((res) => {
        setState({
          ...state,
          data: res.data,
          status: "resolved",
          clicked: "",
        });

        const invitation = res.data;
        updateEvent(
          { ...event, invitation, canInviteGuests: false, enquire: null },
          "decline"
        );
        enqueueSnackbar("You successfully declined the invitation", {
          variant: "success",
        });
      })
      .catch((onError) => {
        console.log(onError);
        setState({
          ...state,
          error: "Something went wrong",
          status: "rejected",
          clicked: "",
        });

        enqueueSnackbar("Something went wrong", { variant: "error" });
      });
  }

  function handleCancelAttendance() {
    setState({
      ...state,
      status: "pending",
      clicked: "cancel",
    });
    authAxios({
      url: `event/table/invitations/${invitation.id}`,
      method: "DELETE",
    })
      .then(() => {
        setState({
          ...state,
          status: "resolved",
          clicked: "",
        });
        updateEvent(
          { ...event, invitation: null, canInviteGuests: false, enquire: null },
          "cancel"
        );
        enqueueSnackbar("You successfully cancelled the invitation", {
          variant: "success",
        });
      })
      .catch((onError) => {
        console.log(onError);
        setState({
          ...state,
          status: "rejected",
          clicked: "",
          error: "Something went wrong",
        });
        enqueueSnackbar("Something went wrong", { variant: "error" });
      });
  }

  return (
    <CardWraper>
      <CardMedia className={classes.cover} image={coverPhoto} title="">
        <Time>{moment(startAt).format("Do MMMM y")}</Time>
      </CardMedia>
      <CardContent>
        <Typography align="left" variant="body2" component="h1">
          <Link
            className={classes.title}
            underline="always"
            component={RouterLink}
            to={`/events/${id}`}
            variant="h4"
          >
            {title}
          </Link>
          <Grid container style={{ marginTop: "20px", marginBottom: "10px" }}>
            <Grid item xs={12} sm={3}>
              <Typography className={classes.testSmall}>
                Place:{" "}
                <Link
                  className={classes.testSmall}
                  color="secondary"
                  underline="always"
                  component={RouterLink}
                  to={``}
                >
                  {location}
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.testSmall}>
                Time: {timings}
              </Typography>
            </Grid>
          </Grid>
          <Typography dangerouslySetInnerHTML={{ __html: shortAbout }} />
          <div style={{ display: "flex", marginTop: "10px" }}>
            <img src={PriceIcon} alt="price" />
            <Typography
              className={classes.testSmall}
              style={{ paddingLeft: "10px" }}
            >
              {price}
            </Typography>
          </div>
        </Typography>
        {canInviteGuests && (
          <Link
            className={classes.title}
            underline="always"
            component={RouterLink}
            to={`/events/${id}/invite`}
            variant="h4"
          >
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: "15px" }}
            >
              Invite guests
              <ArrowRightAltIcon />
            </Button>
          </Link>
        )}
        {invitation?.status === "pending" && (
          <>
            <Link
              className={classes.title}
              underline="always"
              component={RouterLink}
              // to={`/events/${id}/invite`}
              variant="h4"
            >
              <Button
                variant="contained"
                // color="primary"
                style={{ marginTop: "15px" }}
                onClick={handleConfirm}
                disabled={status === "pending"}
                startIcon={
                  status === "pending" && clicked === "confirm" ? (
                    <CircularProgress size="1rem" color="inherit" />
                  ) : (
                    <DoneIcon />
                  )
                }
              >
                Confirm invitation
              </Button>
            </Link>
            <Link
              className={classes.title}
              underline="always"
              component={RouterLink}
              // to={`/events/${id}/invite`}
              variant="h4"
            >
              <Button
                variant="contained"
                // color="primary"
                style={{ marginTop: "15px" }}
                onClick={handleDecline}
                disabled={status === "pending"}
                startIcon={
                  status === "pending" && clicked === "decline" ? (
                    <CircularProgress size="1rem" color="inherit" />
                  ) : (
                    <HighlightOffIcon />
                  )
                }
              >
                Decline invitation
              </Button>
            </Link>
          </>
        )}
        {invitation?.status === "confirmed" && (
          <>
            <Link
              className={classes.title}
              underline="always"
              component={RouterLink}
              // to={`/events/${id}/invite`}
              variant="h4"
            >
              <Button
                variant="contained"
                // color="primary"
                style={{ marginTop: "15px" }}
                onClick={handleCancelAttendance}
                disabled={status === "pending"}
                startIcon={
                  status === "pending" && clicked === "cancel" ? (
                    <CircularProgress size="1rem" color="inherit" />
                  ) : (
                    <HighlightOffIcon />
                  )
                }
              >
                Cancel Attendance
              </Button>
            </Link>
          </>
        )}
      </CardContent>
      <ArrowComponent />
      <Divider>
        <Grid container style={{ height: "8px" }}>
          <Grid item xs={3} style={{ background: "#B9D3F4", height: "8px" }} />
          <Grid item xs={3} style={{ background: "#1287E4", height: "8px" }} />
          <Grid item xs={3} style={{ background: "#0062DF", height: "8px" }} />
          <Grid item xs={3} style={{ background: "#0038AC", height: "8px" }} />
        </Grid>
      </Divider>
    </CardWraper>
  );
}

export default EventDataView;
