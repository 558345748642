import React from "react";
import { HeroContent } from "./styles";
import MulticolorBottomLine from "./multicolorBottomLine";

export default function Hero({ cover, backgroundColor, ...props }) {
  return (
    <HeroContent cover={cover} backgroundColor={backgroundColor}>
      <>
        <div style={{ position: "relative", padding: "60px 50px" }}>
          {props.children}
        </div>
        <MulticolorBottomLine />
      </>
    </HeroContent>
  );
}
