import React, { createContext, useContext } from "react";
import axios from "axios";
import { AuthContext } from "./AuthContext";

const HttpContext = createContext();
const { Provider } = HttpContext;

const HttpProvider = ({ children }) => {
  const authContext = useContext(AuthContext);

  const publicAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  authAxios.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${authContext.getAccessToken()}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return (
    <Provider
      value={{
        authAxios,
        publicAxios,
      }}
    >
      {children}
    </Provider>
  );
};

export { HttpContext, HttpProvider };
