import styled from "styled-components";

export const FooterContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin-top: 32px;
  padding: 24px 20px;
  position: relative;

  > * {
    min-width: 0;
    align-self: center;
  }

  .links {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: flex-start;
    > * {
      font-size: 12px;
      padding-right: 30px;
    }
  }
  .contactLinks {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: flex-start;
    > * {
      font-size: 12px;
      padding-right: 30px;
    }
    @media screen and (min-width: 1200px) {
      justify-content: flex-end;
    }
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
