import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import Input from "../common/Input";
import { useForm, Form } from "../useForm";
import MyButton from "../common/buttons/MyButton";
import FormControl from "@material-ui/core/FormControl";

const initialFValues = {
  id: 0,
  email: "",
};

export default function EventInvitationAddEmailForm({
  addOrEdit,
  recordForEdit,
  enquireKey,
}) {
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues)
      temp.email = /$^|.+@.+..+/.test(fieldValues.email)
        ? ""
        : "Email is not valid.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x.toString() === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  useEffect(() => {
    if (recordForEdit !== null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);

  const addEmail = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, enquireKey);
      resetForm();
    } else {
      let temp = { ...errors };
      temp.email = "Email is not valid.";
      setErrors({ ...temp });
    }
  };

  return (
    <Form>
      <Grid container spacing={1}>
        <Grid item md="9">
          <Input
            name="email"
            label="add guest email"
            value={values.email}
            onChange={handleInputChange}
            error={errors.email}
            color="secondary"
            maxWidth
            width="100%"
          />
        </Grid>
        <Grid item md="3">
          <FormControl>
            {/*<MyButton*/}
            {/*  fullWidth*/}
            {/*  variant="contained"*/}
            {/*  text="Reset"*/}
            {/*  color="default"*/}
            {/*  onClick={resetForm}*/}
            {/*/>*/}
            <MyButton
              variant="contained"
              color="secondary"
              text={recordForEdit ? `Update` : `Add`}
              onClick={addEmail}
              size="md"
              margin="0"
              //style={{margin: 0, height: '50px'}}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Form>
  );
}
