import React, { useContext } from "react";

// import Add from "images/add.svg";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

import {
  InviteFormHolder,
  // MediaBlock,
  // MediaBlockImage,
  // MediaBlockBody,
} from "components/invite/styles";
import * as yup from "yup";
import "yup-phone";
import { useFormik } from "formik";
import { HttpContext } from "../../context/HttpContext";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFilledInput-input": {
      background: "#ffffff",
    },
  },
}));

function InviteForm({ tableId, used, addInvite }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { authAxios } = useContext(HttpContext);
  const validationSchema = yup.object({
    firstName: yup
      .string("Enter first name")
      .required("This is mandatory field"),
    lastName: yup.string("Enter last name").required("This is mandatory field"),
    email: yup
      .string("Enter an email")
      .email("Enter a valid email")
      .required("This is mandatory field"),
    phone: yup
      .string("Enter phone number")
      .phone()
      // .matches(phoneRegExp, 'Mobile number is not valid')
      .required("This is mandatory field"),
  });
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      if (tableId === undefined) return;

      authAxios
        .post(`event/table/invitation`, {
          "form[firstName]": values.firstName,
          "form[lastName]": values.lastName,
          "form[email]": values.email,
          "form[phone]": values.phone,
          "form[eventTable]": tableId,
        })
        .then((res) => {
          const { data } = res;
          actions.resetForm({
            values: {
              // the type of `values` inferred to be Blog
              firstName: "",
              lastName: "",
              email: "",
              phone: "",
            },
          });

          if (data.status === "success") {
            addInvite(data.data);
            enqueueSnackbar("You successfully sent invitation", {
              variant: "success",
            });
          }
        })
        .catch((onError) => {
          console.log(onError);
          const { data } = onError.response;
          if (data) {
            if (data.status === "error") {
              if (
                data.status_msg.startsWith(
                  "This person is already attending this event."
                )
              ) {
                actions.setFieldError(
                  "email",
                  "This person is already attending this event."
                );
              }
            }
          }
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <InviteFormHolder>
          <Typography variant="h5">
            Guest {used + 1}
            {/*Guest {used + 1} <span style={{ fontSize: "18px" }}>(Host)</span>*/}
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <p>
                First name<sup style={{ color: "#1287E4" }}>*</sup>
              </p>
              <TextField
                autoFocus={Boolean(tableId)}
                id="firstName"
                name="firstName"
                placeholder="First name"
                fullWidth
                variant="filled"
                autoComplete="given-name"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.errors.firstName}
                className={classes.root}
              />
            </Grid>
            <Grid item md={6}>
              <p>
                Last name<sup style={{ color: "#1287E4" }}>*</sup>
              </p>
              <TextField
                id="lastName"
                name="lastName"
                placeholder="Last name"
                fullWidth
                variant="filled"
                autoComplete="family-name"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.errors.lastName}
                className={classes.root}
              />
            </Grid>
            <Grid item md={6}>
              <p>
                Email address<sup style={{ color: "#1287E4" }}>*</sup>
              </p>
              <TextField
                id="email"
                name="email"
                placeholder="email@example.com"
                fullWidth
                variant="filled"
                autoComplete="given-name"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.errors.email}
                className={classes.root}
              />
            </Grid>
            <Grid item md={6}>
              <p>
                Phone number<sup style={{ color: "#1287E4" }}>*</sup>
              </p>
              <TextField
                id="phone"
                name="phone"
                placeholder="080012345678"
                fullWidth
                variant="filled"
                autoComplete="shipping tel-local-prefix"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.errors.phone}
                className={classes.root}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <small>
                <sup style={{ color: "#1287E4" }}>*</sup>Mandatory fields
              </small>
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={
                  !(formik.isValid && formik.dirty) || formik.isSubmitting
                }
                type="submit"
                variant="contained"
                color="primary"
                endIcon={<ArrowRightAltIcon />}
              >
                Send invite
              </Button>
            </Grid>
          </Grid>
        </InviteFormHolder>
        {/*<MediaBlock>*/}
        {/*  <MediaBlockImage>*/}
        {/*    <img src={Add} alt={""} />*/}
        {/*  </MediaBlockImage>*/}
        {/*  <MediaBlockBody>*/}
        {/*    <span>Add guest email</span>*/}
        {/*    <small>You can add up to 12 guests.</small>*/}
        {/*  </MediaBlockBody>*/}
        {/*</MediaBlock>*/}
        {/*<Button*/}
        {/*  variant="contained"*/}
        {/*  color="primary"*/}
        {/*  fullWidth*/}
        {/*  style={{ margin: "40px 20px 0" }}*/}
        {/*>*/}
        {/*  Send all invites*/}
        {/*</Button>*/}
      </form>
    </>
  );
}

export default InviteForm;
