import React, { useContext, useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { HttpContext } from "../../context/HttpContext";
import Hero from "../common/hero";
import TabsSection from "./tabs";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import EventInvitationAddEmailForm from "./EventInvitationAddEmailForm";
import EventInvitationNumberOfPersonsForm from "./EventInvitationNumberOfPersonsForm";
import EventInvitationMessageForm from "./EventInvitationMessageForm";
import EmailsTable from "../common/table/Emails";
import {
  getAllEmails,
  insertEmail,
  updateEmail,
} from "../../services/emailServices";
import map from "lodash/map";
import Notification from "../common/actions/Notification";
import moment from "moment";
import { useSnackbar } from "notistack";
// import { Link as RouterLink } from "react-router-dom";
import DoneIcon from "@material-ui/icons/Done";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
// import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SingleEvent({ id, userId, isAdmin }) {
  const enquireKey = `${userId}-${id}`;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  let initAjaxCall = {
    status: "idle",
    clicked: "",
  };
  const [ajaxCall, setAjaxCall] = useState(initAjaxCall);
  const [error, setError] = useState(null);
  const [event, setEvent] = useState(null);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [records, setRecords] = useState(getAllEmails(enquireKey));
  const [
    // openPopup
    ,
    setOpenPopup] = useState(false);
  const [open, setOpen] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [isEnquired, setIsEnquired] = useState(false);

  const { authAxios } = useContext(HttpContext);

  useEffect(() => {
    const getEventData = async () => {
      try {
        setLoading(true);
        const { data } = await authAxios.get(`get-single-event?id=${id}`);
        if (data.status === "success") {
          const event = (data && data.data && data.data.event) || null;
          if (event) {
            setEvent(event);
          }
        } else {
          setError(`Something's wrong happened`);
          setEvent(null);
        }
        setLoading(false);
      } catch (onError) {
        console.log(onError);
        setLoading(false);
      }
    };
    getEventData();
  }, [authAxios, id]);

  const invitation = event?.invitation ?? null;
  // const enquire = event?.enquire ?? null;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function clearLocalStorageItems() {
    localStorage.removeItem(`${enquireKey}-message`);
    localStorage.removeItem(`${enquireKey}-numberOfPersons`);
    localStorage.removeItem(`${enquireKey}-emails`);
  }

  function handleConfirm() {
    const invitationId = event.invitation.id ?? null;

    if (null === invitationId) {
      return;
    }

    setAjaxCall({
      status: "pending",
      clicked: "confirm",
    });

    authAxios({
      url: `event/table/invitations/${invitationId}/confirm`,
      method: "POST",
    })
      .then((res) => {
        setAjaxCall({
          status: "resolved",
          clicked: "",
        });
        const invitation = res.data;
        let canInviteGuests = false;
        if (invitation?.type?.label === "Host") {
          canInviteGuests = true;
        }
        // setEvent({ ...event, invitation });
        setEvent({ ...event, invitation, canInviteGuests });
        setIsEnquired(true);
        enqueueSnackbar("You successfully confirmed the invitation", {
          variant: "success",
        });
      })
      .catch((onError) => {
        console.log(onError);

        setAjaxCall({
          status: "rejected",
          clicked: "",
        });
        enqueueSnackbar("Something went wrong", { variant: "error" });
      });
    // enqueueSnackbar("You successfully confirm the invitation", {
    //   variant: "success",
    // });
  }

  function handleDecline() {
    const invitationId = event.invitation.id ?? null;

    if (null === invitationId) {
      return;
    }
    setAjaxCall({
      status: "pending",
      clicked: "confirm",
    });

    // enqueueSnackbar("You successfully decline the invitation", {
    //   variant: "success",
    // });
    authAxios({
      url: `event/table/invitations/${invitationId}/decline`,
      method: "POST",
    })
      .then((res) => {
        setAjaxCall({
          status: "resolved",
          clicked: "",
        });
        const invitation = res.data;
        setEvent({
          ...event,
          invitation,
          canInviteGuests: false,
          enquire: null,
        });
        setIsEnquired(false);
        enqueueSnackbar("You successfully declined the invitation", {
          variant: "success",
        });
      })
      .catch((onError) => {
        console.log(onError);

        setAjaxCall({
          status: "rejected",
          clicked: "",
        });

        enqueueSnackbar("Something went wrong", { variant: "error" });
      });
  }

  function handleCancelAttendance() {
    const invitationId = event.invitation.id ?? null;

    if (null === invitationId) {
      return;
    }
    setAjaxCall({
      status: "pending",
      clicked: "cancel",
    });

    authAxios({
      url: `event/table/invitations/${invitationId}`,
      method: "DELETE",
    })
      .then(() => {
        setAjaxCall({
          status: "resolved",
          clicked: "",
        });
        setEvent({
          ...event,
          invitation: null,
          canInviteGuests: false,
          enquire: null,
        });
        setIsEnquired(false);
        enqueueSnackbar("You successfully cancelled the invitation", {
          variant: "success",
        });
      })
      .catch((onError) => {
        console.log(onError);

        setAjaxCall({
          status: "rejected",
          clicked: "",
        });

        enqueueSnackbar("Something went wrong", { variant: "error" });
      });
  }

  const handleSend = async (e) => {
    e.preventDefault();

    if (canSendOnlyInvitation) {
      const emailList = JSON.parse(
        localStorage.getItem(`${enquireKey}-emails`)
      );
      let emails = [];
      if (emailList.length > 0) {
        map(emailList, (emailItem) => {
          emails.push(emailItem.email);
        });
      }
      const data = {
        eventId: id,
        emails,
      };

      try {
        const res = await authAxios.post(`send-event-invitations-emails`, data);
        clearLocalStorageItems();
        setRecords(getAllEmails(enquireKey));
        setOpen(false);
      } catch (onError) {
        console.log(onError);
      }

      return;
    }

    const message = localStorage.getItem(`${enquireKey}-message`);
    const numOfPersons =
      localStorage.getItem(`${enquireKey}-numberOfPersons`) || 1;
    const emailList = JSON.parse(localStorage.getItem(`${enquireKey}-emails`));
    let emails = [];
    if (emailList.length > 0) {
      map(emailList, (emailItem) => {
        emails.push(emailItem.email);
      });
    }
    const data = {
      eventId: id,
      numOfPersons,
      message,
      emails,
    };

    try {
      await authAxios.post(`send-enquiry`, data);
      clearLocalStorageItems();
      setRecords(getAllEmails(enquireKey));
      setIsEnquired(true);
      setOpen(false);
    } catch (onError) {
      console.log(onError);
    }
  };

  const addOrEdit = (email, key) => {
    if (parseInt(email.id, 10) === 0) {
      insertEmail(email, key);
    } else {
      updateEmail(email, key);
    }
    setRecordForEdit(null);
    // setOpenPopup(false);
    setRecords(getAllEmails(key));

    setNotify({
      isOpen: true,
      message: recordForEdit ? "Updated successfully" : "Added successfully",
      type: "success",
    });
  };

  const openInPopup = (item, key) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const hasEnquired = event?.enquire || isEnquired;
  const canSendOnlyInvitation = (hasEnquired && isAdmin) || false;
  const canInviteGuests = event?.canInviteGuests ?? false;

  return (
    <>
      {loading && <CircularProgress />}
      {error && <div>Err: {error}</div>}
      {event ? (
        <>
          <Container maxWidth="lg" pt="50px" style={{ marginBottom: "10px" }}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="secondary" href="/" style={{ fontSize: "14px" }}>
                Home
              </Link>
              <Link
                color="secondary"
                href="/events"
                style={{ fontSize: "14px" }}
              >
                Events
              </Link>
            </Breadcrumbs>
          </Container>
          <Hero backgroundColor="rgba(0, 0, 0, 0.25)" cover={event.coverPhoto}>
            <Typography
              variant="h2"
              style={{ color: "white", textDecoration: "underline" }}
            >
              {event.title}
            </Typography>
            <Typography
              variant="h4"
              style={{ color: "white", marginTop: "24px" }}
            >
              {event.shortAbout}
            </Typography>
            <div style={{ color: "white" }}>
              <p style={{ marginBottom: "0" }}>
                Date:{" "}
                <span style={{ color: "#ffffff7a" }}>
                  {moment(event.startAt).format("dddd, Do MMMM y.")}
                </span>
              </p>
              <div style={{ display: "flex" }}>
                <p>
                  Time:{" "}
                  <span style={{ color: "#ffffff7a" }}>{event.timings}</span>
                </p>
                <p style={{ marginLeft: "5px" }}>
                  Place:{" "}
                  <span style={{ color: "#ffffff7a" }}>{event.location}</span>
                </p>
              </div>
              {(!hasEnquired || canSendOnlyInvitation) && !invitation?.status && (
                <Button
                  variant="contained"
                  onClick={handleClickOpen}
                  disabled={ajaxCall.status === "pending"}
                >
                  {canSendOnlyInvitation
                    ? "Send invitations"
                    : "Enquire for this event"}
                  <ArrowRightAltIcon />
                </Button>
              )}
              {canInviteGuests && (
                <Button
                  component={Link}
                  variant="contained"
                  href={`/events/${id}/invite`}
                >
                  Invite guests
                  <ArrowRightAltIcon />
                </Button>
              )}
              {invitation?.status === "pending" && (
                <>
                  <Button
                    component={Link}
                    variant="contained"
                    onClick={handleConfirm}
                    disabled={ajaxCall.status === "pending"}
                    startIcon={
                      ajaxCall.status === "pending" &&
                      ajaxCall.clicked === "confirm" ? (
                        <CircularProgress size="1rem" color="inherit" />
                      ) : (
                        <DoneIcon />
                      )
                    }
                  >
                    Confirm invitation
                  </Button>
                  <Button
                    component={Link}
                    variant="contained"
                    onClick={handleDecline}
                    disabled={ajaxCall.status === "pending"}
                    startIcon={
                      ajaxCall.status === "pending" &&
                      ajaxCall.clicked === "decline" ? (
                        <CircularProgress size="1rem" color="inherit" />
                      ) : (
                        <HighlightOffIcon />
                      )
                    }
                  >
                    Decline invitation
                  </Button>
                </>
              )}
              {invitation?.status === "confirmed" && (
                <Button
                  component={Link}
                  variant="contained"
                  onClick={handleCancelAttendance}
                  disabled={ajaxCall.status === "pending"}
                  startIcon={
                    ajaxCall.status === "pending" &&
                    ajaxCall.clicked === "cancel" ? (
                      <CircularProgress size="1rem" color="inherit" />
                    ) : (
                      <HighlightOffIcon />
                    )
                  }
                >
                  Cancel Attendance
                </Button>
              )}
              <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
              >
                <AppBar className={classes.appBar}>
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={handleClose}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                      {canSendOnlyInvitation
                        ? "Send invitations"
                        : "Enquire for this event"}
                    </Typography>
                    <Button autoFocus color="inherit" onClick={handleSend}>
                      Send
                    </Button>
                  </Toolbar>
                </AppBar>
                <Container maxWidth="sm" style={{ paddingTop: "60px" }}>
                  {!canSendOnlyInvitation && (
                    <EventInvitationNumberOfPersonsForm
                      enquireKey={enquireKey}
                    />
                  )}
                  {!canSendOnlyInvitation && (
                    <EventInvitationMessageForm enquireKey={enquireKey} />
                  )}
                  <EventInvitationAddEmailForm
                    recordForEdit={recordForEdit}
                    addOrEdit={addOrEdit}
                    enquireKey={enquireKey}
                  />
                </Container>

                <Container maxWidth="sm" style={{ paddingTop: "20px" }}>
                  <EmailsTable
                    records={records}
                    setRecords={setRecords}
                    openInPopup={openInPopup}
                    enquireKey={enquireKey}
                  />
                </Container>
                {/*{records.length > 0 && <List>*/}
                {/*  <EmailsTable*/}
                {/*    records={records}*/}
                {/*    setRecords={setRecords}*/}
                {/*    openInPopup={openInPopup}*/}
                {/*    id={id}*/}
                {/*  />*/}
                {/*</List>}*/}
              </Dialog>
            </div>
          </Hero>
          <Container maxWidth="md">
            <TabsSection
              event={event}
              isEnquired={isEnquired}
              enquireOpen={handleClickOpen}
            />
          </Container>
          <Notification notify={notify} setNotify={setNotify} />
        </>
      ) : null}
    </>
  );
}

export default SingleEvent;
