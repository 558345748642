import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import { Link as RouterLink } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import LogoutButton from "../common/LogoutButton";
import HeaderDrawer from "./Drawer";
import Logo from "../../images/logo.svg";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function Header() {
  const auth = useContext(AuthContext);
  const isAuthenticated = (auth && auth.isAuthenticated()) || false;

  const classes = useStyles();
  return (
    <header>
      <Toolbar className={classes.toolbar}>
        <Grid item xs={3}>
          <Link className="is-mobile" as={RouterLink} to="/">
            <img src={Logo} alt="Logo" width="90px" height="90px" />
          </Link>
        </Grid>
        <Grid item xs={5}>
          {!isAuthenticated ? (
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  component={RouterLink}
                  to="/login"
                  variant="outlined"
                  color="primary"
                  margin="normal"
                  size="small"
                >
                  Log in
                </Button>
                {/*<span> or</span>*/}
              </Grid>
              <Grid item style={{ alignSelf: "center" }} >or</Grid>
              <Grid item>
                <Button
                  component={RouterLink}
                  to="/register"
                  variant="contained"
                  color="primary"
                  margin="normal"
                  size="small"
                >
                  Register
                </Button>
              </Grid>
            </Grid>
          ) : (
            <LogoutButton />
          )}
        </Grid>

        <Grid item xs={4}>
          <Typography
            component="h2"
            variant="h5"
            color="inherit"
            align="right"
            noWrap
            className={classes.toolbarTitle}
          >
            <HeaderDrawer isAuthenticated={isAuthenticated} />
          </Typography>
        </Grid>
      </Toolbar>
    </header>
  );
}
