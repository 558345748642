// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.scss";
import "./swiperStyles.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default function Slider({ img }) {
  //img = { img }
  return (
    <Swiper
      spaceBetween={0}
      slidesPerView={1}
      allowTouchMove={true}
      pagination={{
        el: ".swiper-pagination",
        type: "bullets",
        clickable: "true",
      }}
      navigation
      style={{
        height: "100%",
        width: "500px",
      }}
      //   scrollbar={{ draggable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log("slide change")}
    >
      <SwiperSlide
        style={{
          background: `url(${img}) no-repeat`,
          backgroundSize: "cover",
        }}
      >
        {/* <img src={img} alt="event" style={{ width: '100%' }} /> */}
      </SwiperSlide>
      <SwiperSlide>Slide 2</SwiperSlide>
      <SwiperSlide>Slide 3</SwiperSlide>
      <div className="swiper-pagination" />
    </Swiper>
  );
}
