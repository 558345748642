import styled from "styled-components";

export const HeroContent = styled.div`
  position: relative;
  background-image: url("${(props) => props.cover}");
  background-size: cover;
  &:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.backgroundColor};
    backdrop-filter: blur(20px);
  }
`;
export const MulticolorLine = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

export const ShareComponent = styled.div`
  position: absolute;
  top: 650px;
  margin-left: 40px;
  @media screen and (max-width: 960px) {
    top: 0;
    position: relative;
    display: block;
    width: 100%;
  }
`;
