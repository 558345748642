// import findIndex from 'lodash/findIndex'

export function insertEmail(data, key) {
  let emails = getAllEmails(key);
  data["id"] = generateEmailId(key);
  emails.push(data);
  localStorage.setItem(`${key}-emails`, JSON.stringify(emails));
}

export function updateEmail(data, key) {
  let emails = getAllEmails(key);
  let recordIndex = emails.findIndex((x) => x.id === data.id);
  emails[recordIndex] = { ...data };
  localStorage.setItem(`${key}-emails`, JSON.stringify(emails));
}

export function deleteEmail(id, key) {
  let emails = getAllEmails(key);
  emails = emails.filter((x) => x.id !== id);
  localStorage.setItem(`${key}-emails`, JSON.stringify(emails));
}

export function generateEmailId(key) {
  if (localStorage.getItem(`${key}-emailId`) === null) {
    localStorage.setItem(`${key}-emailId`, "0");
  }
  let id = parseInt(localStorage.getItem(`${key}-emailId`));
  localStorage.setItem(`${key}-emailId`, (++id).toString());
  return id;
}

export function getAllEmails(key) {
  if (localStorage.getItem(`${key}-emails`) === null) {
    localStorage.setItem(`${key}-emails`, JSON.stringify([]));
  }

  return JSON.parse(localStorage.getItem(`${key}-emails`));
  //map departmentID to department title
  // let departments = getDepartmentCollection();
  // return emails;
  // return emails.map(x => ({
  //   ...x,
  //   department: departments[x.departmentId - 1].title
  // }))
}

export function getCurrentEmailId(key) {
  return generateEmailId(key);
}

export function clearAllInEmailService(key) {
  clearEmailId(key);
  clearAllEmails(key);
}

export function clearEmailId(key) {
  if (localStorage.getItem(`${key}-emailId`) !== null) {
    localStorage.removeItem(`${key}-emailId`);
  }
}
export function clearAllEmails(key) {
  if (localStorage.getItem(`${key}-emails`) !== null) {
    localStorage.setItem(`${key}-emails`, JSON.stringify([]));
  }
}
