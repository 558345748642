import React, { useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import SignInForm from "../components/auth/form/SignInForm";
import LoginCodeActivationPopup from "../components/auth/popup/LoginCodeActivation";
import ActivateAccountForm from "../components/auth/form/ActivateAccountForm";
import { SideContent, MainContent } from "../layout/styles";

export default function SignIn({ location }) {
  const [openPopup, setOpenPopup] = useState(false);

  return (
    <>
      <>
        <SideContent />
        <MainContent style={{ marginTop: "170px", height: "100vh" }}>
          {" "}
          <CssBaseline />
          <Container
            maxWidth="md"
            style={{ marginLeft: "0px", paddingLeft: "0px" }}
          >
            <Typography component="h1" variant="h3" align="left">
              Log in
            </Typography>
            <SignInForm setOpenPopup={setOpenPopup} location={location} />
            <LoginCodeActivationPopup
              title="Please enter code and activate your account"
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
            >
              <ActivateAccountForm location={location} />
            </LoginCodeActivationPopup>
          </Container>
        </MainContent>
      </>
    </>
  );
}
