import TextField from "@material-ui/core/TextField";
import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { HttpContext } from "../../../context/HttpContext";
import * as yup from "yup";
import { useFormik } from "formik";
import { AuthContext } from "../../../context/AuthContext";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    textTransform: "none",
  },
}));

export default function AutomaticSignInForm({ setOpenPopup, email, password }) {
  const classes = useStyles();
  const authContext = useContext(AuthContext);
  const { publicAxios } = useContext(HttpContext);

  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .min(6, "Password should be of minimum 6 characters length")
      .required("Password is required"),
  });

  useEffect(() => {
    publicAxios
      .post(`login`, {
        "form[email]": email,
        "form[password]": password,
      })
      .then((res) => {
        const { data } = res;
        if (data.status === "success") {
          const { user, apiToken, expiresAt } = data.data;
          authContext.setAuthState({
            token: apiToken,
            userInfo: user,
            expiresAt,
          });
        }
      })
      .catch((onError) => {
        if (onError.response) {
          const { data } = onError.response;
          console.log(onError);
          if (data) {
            if (data.status === "error") {
              if (data.status_msg === "Please confirm your account") {
                setOpenPopup(true);
              }
            }
          }
        }
      });
  }, []);

  const formik = useFormik({
    initialValues: {
      email: email || "",
      password: password || "",
    },
    validationSchema: validationSchema,
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <p style={{ marginBottom: "0" }}>Email address</p>
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              placeholder="email@example.com"
              autoFocus
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <p style={{ margin: "0" }}>Password</p>
            <TextField
              margin="normal"
              required
              variant="filled"
              fullWidth
              id="password"
              type="password"
              name="password"
              autoComplete="current-password"
              placeholder="••••••••"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              defaultValue=""
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
}
