import React from "react";
import Grid from "@material-ui/core/Grid";
import { MulticolorLine } from "./styles";

export default function MulticolorBottomLine() {
  return (
    <MulticolorLine>
      <Grid container style={{ height: "8px" }}>
        <Grid item xs={3} style={{ background: "#B9D3F4", height: "8px" }} />
        <Grid item xs={3} style={{ background: "#1287E4", height: "8px" }} />
        <Grid item xs={3} style={{ background: "#0062DF", height: "8px" }} />
        <Grid item xs={3} style={{ background: "#0038AC", height: "8px" }} />
      </Grid>
    </MulticolorLine>
  );
}
