import React, { useState } from "react";
import Input from "../common/Input";
import { useForm, Form } from "../useForm";

const initialFValues = {
  id: 0,
  message: "",
};

export default function EventInvitationMessageForm({ enquireKey }) {
  const [message, setMessage] = useState(
    localStorage.getItem(`${enquireKey}-message`)
  );

  const handleBlur = (event) => {
    // console.log(event.target.value);

    localStorage.setItem(`${enquireKey}-message`, event.target.value);
    setMessage(event.target.value);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    // if ("message" in fieldValues) temp.message = fieldValues.message ? "" : "This field is required.";
    // if ('message' in fieldValues) temp.message = fieldValues.message.length > 0 ? "" : "Minimum 1."
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x.toString() === "");
  };

  const { values, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  return (
    <Form>
      <Input
        multiline
        rows={4}
        name="message"
        label="Booking notes"
        placeholder="Notes"
        defaultValue={message}
        onChange={handleInputChange}
        error={errors.message}
        onBlur={handleBlur}
        color="secondary"
        maxWidth
      />
    </Form>
  );
}
