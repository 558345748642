import { createMuiTheme } from "@material-ui/core/styles";
import { nominalTypeHack } from "prop-types";
const font = "'Poppins', sans-serif";
export const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 794,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#000000",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#0062DF",
    },
    white: {
      main: "#fff",
    },
  },
  typography: {
    fontFamily: font,

    h3: {
      fontSize: "52px",
      fontWeight: "600",
    },

    h5: {
      fontSize: "24px",
      fontWeight: "500",
    },
  },
  overrides: {
    MuiDrawer: {
      paperAnchorRight: {
        right: "0",
        left: "0",
        background: "#000",
        color: "#fff",
      },
      MuiAccordion: {},
    },
    MuiFilledInput: {
      input: {
        background: "#f2f2f2",
      },
    },
    MuiButton: {
      root: {
        borderRadius: "0",
        padding: "13px 32px",
      },
      contained: {
        boxShadow: "none",
      },
      sizeSmall: {
          paddingLeft: "24px",
          paddingRight: "24px",
          fontWeight: "400",
          fontSize: "14px"
      }
    },
    MuiContainer: {
      maxWidthSm: {
        maxWidth: "834px",
      },
    },
    MuiTab: {
      root: {
        padding: "0",
        fontSize: "0",
        maxWidth: "290px",
        minHeight: "auto",
        textAlign: "left",
        fontWeight: "normal",
        lineHeight: "normal",
        textTransform: "none",
        "&$selected": {
          background: "#ffffff",
        },
      },
    },
  },
});
