import React, { useContext } from "react";
import SingleEvent from "../components/event/Single";
import { useParams } from "react-router-dom";
import { SideContent, MainContent } from "../layout/styles";
// import Share from "../components/common/share";
import { AuthContext } from "../context/AuthContext";
import ScrollToTop from "../components/scrollToTop";

function Event() {
  const { id } = useParams();
  const auth = useContext(AuthContext);

  return (
    <>
      <SideContent>
        {/*<Share />*/}
      </SideContent>
      <MainContent>
        <ScrollToTop />
        <SingleEvent
          id={id}
          userId={auth.getUserId()}
          isAdmin={auth.isAdmin()}
        />
      </MainContent>
    </>
  );
}

export default Event;
