import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    borderBottom: "2px solid white",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.action.hover,
    opacity: 0.5,

    "&:last-child": {
      opacity: 1,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function Price({ event, isEnquired, enquireOpen }) {
  const classes = useStyles();

  function handleEnquireOpen() {
    enquireOpen();
  }

  return (
    <>
      <Typography variant="h4" style={{ marginBottom: "20px" }}>
        Price
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead />
          <TableBody>
            <StyledTableRow key={1}>
              <StyledTableCell component="th" scope="row">
                <Typography variant="h6">Early bird</Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                {" "}
                <Typography color="secondary">£ 29.50</Typography>
                <Typography> +£ 2.95 booking fee</Typography>
              </StyledTableCell>
              <StyledTableCell align="left" />
            </StyledTableRow>
            <StyledTableRow key={2}>
              <StyledTableCell component="th" scope="row">
                <Typography variant="h6">First Release</Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                {" "}
                <Typography color="secondary">£ 35.50</Typography>
                <Typography> +£ 3.45 booking fee</Typography>
              </StyledTableCell>
              <StyledTableCell align="left" />
            </StyledTableRow>

            <StyledTableRow key={3}>
              <StyledTableCell component="th" scope="row">
                <Typography variant="h6">Final Release</Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                {" "}
                <Typography color="secondary">£ 39.50</Typography>
                <Typography> +£ 3.95 booking fee</Typography>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{ padding: "0px!important" }}
              >
                {event.enquire === null && !isEnquired && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={handleEnquireOpen}
                  >
                    enquire for this event
                    <ArrowRightAltIcon />
                  </Button>
                )}
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
