import styled from "styled-components";
import Arrow from "../../images/arrow-right.svg";

export const Divider = styled.div``;
export const CardContent = styled.div``;
export const ArrowComponent = styled.div``;
export const Time = styled.div``;

export const CardWraper = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #00000033;
  padding: 50px 20px 50px 0;
  position: relative;
  transition: 0.2s;
  margin: 0px;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    padding-right: 30px;
  }
  ${Time} {
    background: #0062df;
    padding: 10px 14px;
    display: inline-block;
    color: white;
    font-size: 14px;
    margin-top: 20px;
    margin-left: -17px;
  }
  ${CardContent} {
    padding: 0px 10px 0px 30px;
    @media screen and (max-width: 960px) {
      paddingleft: 0px;
    }
  }
  ${ArrowComponent} {
    background: url(${Arrow}) no-repeat;
    width: 118px;
    height: 80px;
    position: absolute;
    bottom: 40px;
    right: 20px;
    display: none;
    visibility: none;
  }
  ${Divider} {
    display: none;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-left: -50px;
  }
  &:hover {
    background: #b9b9b980;
    padding: 50px 20px 50px 50px;
    margin-left: -50px;
    border-bottom: 0;
  }
  &:hover ${ArrowComponent} {
    display: block;
    transition: 0.2s;
  }
  &:hover ${Divider} {
    display: block;
    transition: 0.2s;
  }
  &:hover ${Time} {
    margin-left: 20px;
    transition: 0.2s;
  }
`;
