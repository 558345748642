import React, { useContext, useState, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { HttpContext } from "../../context/HttpContext";
import map from "lodash/map";
import EventDataView from "./DataView";
import Hero from "../common/hero";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function EventsLists() {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    count: 1,
    events: [],
    hasMore: false,
    total: 0,
  });
  const [error, setError] = useState(null);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const { authAxios } = useContext(HttpContext);

  const { count, events } = data;
  //console.log(data)

  useEffect(() => {
    const getEventsData = async () => {
      try {
        setLoading(true);
        const { data } = await authAxios.get(`get-events?page=${page}`);
        if (data.status === "success") {
          const events = (data && data.data && data.data.events) || null;
          if (events) {
            const hasMore = events.hasMore || false;
            const total = events.numOfItems || 0;
            const count = events.numOfPages || 1;
            const items = events.items || [];
            setData({
              count,
              events: items,
              hasMore,
              total,
            });
          }
        } else {
          setError(`Something's wrong happened`);
          setData({
            count: 0,
            events: [],
            hasMore: false,
            total: 0,
          });
        }

        setLoading(false);
      } catch (onError) {
        console.log(onError);
        setLoading(false);
      }
    };

    getEventsData();
  }, [authAxios, page]);

  function updateEvent(event, type) {
    let events = data.events;
    let recordIndex = events.findIndex((x) => x.id === event.id)
    if (recordIndex > -1) {
      events[recordIndex] = event;
      setData({
        ...data,
        events
      })
    }
  }

  return (
    <>
      <Container maxWidth="lg" style={{ marginBottom: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="secondary" href="/" style={{ fontSize: "14px" }}>
            Home
          </Link>
          <Link color="inherit" style={{ fontSize: "14px" }}>
            Events
          </Link>
        </Breadcrumbs>
      </Container>
      <Hero
        backgroundColor="rgba(0, 98, 223, 0.6)"
        cover="https://previews.123rf.com/images/fordzolo/fordzolo1506/fordzolo150600296/41026708-example-white-stamp-text-on-red-backgroud.jpg"
      >
        <Typography
          variant="h2"
          style={{ color: "white", textDecoration: "underline" }}
        >
          Bookings
        </Typography>
        <Typography variant="h4" style={{ color: "white", marginTop: "24px" }}>
          Invite guests to your events.
        </Typography>
      </Hero>
      <Container maxWidth="lg" style={{ marginTop: "60px" }}>
        <div style={{ marginBottom: "40px" }}>
          {/*<Typography*/}
          {/*  size="36px"*/}
          {/*  variant="h4"*/}
          {/*  style={{*/}
          {/*    fontWeight: "600",*/}
          {/*    textDecoration: "underline",*/}
          {/*    marginBottom: "20px",*/}
          {/*  }}*/}
          {/*>*/}
          {/*  hasta 2021 events calendar{" "}*/}
          {/*</Typography>*/}
          {/*<Typography>*/}
          {/*  hasta creates anything but standard team building and corporate*/}
          {/*  events. Infused with iconic locations, names and sports, we create*/}
          {/*  unapparelled experiences that are as dynamic as our changing*/}
          {/*  environment. With employee wellbeing and corporate health at the*/}
          {/*  heart of what we do, all our events are inclusive and you’ll leave*/}
          {/*  having learnt something new.{" "}*/}
          {/*</Typography>*/}
          {/*<Typography>*/}
          {/*  Events should never end; the experience should stay with you*/}
          {/*  forever.{" "}*/}
          {/*</Typography>*/}
        </div>
        {/*<Forms />*/}
        {/*<div*/}
        {/*  style={{*/}
        {/*    background: "rgb(185 185 185 / 51%)",*/}
        {/*    padding: "40px",*/}
        {/*    marginTop: "50px",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Typography size="24px" variant="h6">*/}
        {/*    Theatre*/}
        {/*  </Typography>*/}
        {/*  <Typography size="18px">*/}
        {/*    Hasta Theatre’s Selection seeks a variety of input from other*/}
        {/*    theatres, the community, and online to select plays. We guaranty*/}
        {/*    quality and good fun.*/}
        {/*  </Typography>*/}
        {/*  <Button*/}
        {/*    variant="contained"*/}
        {/*    color="primary"*/}
        {/*    style={{ marginTop: "15px" }}*/}
        {/*  >*/}
        {/*    visit hasta theatre*/}
        {/*    <ArrowRightAltIcon />*/}
        {/*  </Button>*/}
        {/*  <img style={{ float: "right" }} src={Arrow} alt={""} />*/}
        {/*</div>*/}
        {loading && <CircularProgress />}
        {error && <div>Err: {error}</div>}
        {events.length ? (
          <>
            {" "}
            {map(events, (event) => (
              <EventDataView event={event} key={event.id} updateEvent={updateEvent} />
            ))}
            <div className={classes.root}>
              <Typography> </Typography>
              <Typography> </Typography>
              <Pagination
                count={count}
                // variant="outlined"
                // shape="rounded"
                page={page}
                onChange={handleChange}
              />
            </div>
          </>
        ) : null}
      </Container>
    </>
  );
}

export default EventsLists;
