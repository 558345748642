import { useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";

function Logout() {
  const auth = useContext(AuthContext);
  useEffect(() => {
    auth.logout();
  }, [auth]);

  return null;
}

export default Logout;
