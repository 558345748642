import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import * as PropTypes from "prop-types";
import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {
  // Link as RouterLink,
  useParams
} from "react-router-dom";
import { HttpContext } from "../../context/HttpContext";
import * as yup from "yup";
import { useFormik } from "formik";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function AddEventTableForm({ setOpenPopup, addItem }) {
  const { id } = useParams();
  const classes = useStyles();
  const { authAxios } = useContext(HttpContext);

  const validationSchema = yup.object({
    name: yup.string("Enter table name").required("This is mandatory field"),
    capacity: yup
      .number()
      .typeError("Only numerical input is allowed.")
      .required("This is mandatory field")
      .min(1, "Capacity must be at least 1")
      .max(12, "Maximum of capacity is 12"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      capacity: 1,
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      authAxios
        .post(`event/table`, {
          "form[name]": values.name,
          "form[capacity]": values.capacity,
          "form[event]": id,
        })
        .then((res) => {
          const { data } = res;
          if (data.status === "success") {
            const { capacity, id, eventId, name, used, canAddInvite } = data.data;
            setOpenPopup(false);
            addItem({ capacity, id, eventId, name, used, canAddInvite})
          }
        })
        .catch((onError) => {
          console.log(onError);
          // const { data } = onError.response;
          // if (data) {
          //   if (data.status === "error") {
          //     if (data.status_msg === "Name is required") {
          //       actions.setFieldError("name", "This is mandatory field");
          //     } else if (data.status_msg === "Capacity is not valid") {
          //       actions.setFieldError("code", data.status_msg);
          //     }
          //   }
          // }
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              variant="filled"
              required
              fullWidth
              id="name"
              label="Table name"
              name="name"
              autoComplete="name"
              autoFocus
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.errors.name}
              // helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              margin="normal"
              variant="filled"
              required
              fullWidth
              id="capacity"
              label="Capacity"
              name="capacity"
              autoComplete="capacity"
              type="number"
              value={formik.values.capacity}
              onChange={formik.handleChange}
              error={formik.touched.capacity && Boolean(formik.errors.capacity)}
              helperText={formik.errors.capacity}
              // helperText={formik.touched.capacity && formik.errors.capacity}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              endIcon={<ArrowRightAltIcon />}
              disabled={!(formik.isValid && formik.dirty)}
            >
              Add table
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

AddEventTableForm.propTypes = { classes: PropTypes.any };

export default AddEventTableForm;
