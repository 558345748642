import React, { useState } from "react";
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import useTable from "../../useTable";
import { getAllEmails, deleteEmail } from "../../../services/emailServices";
import ActionButton from "../buttons/ActionButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmDialog from "../actions/ConfirmDialog";
import Notification from "../actions/Notification";

const headCells = [
  // { id: "id", label: "Id" },
  { id: "email", label: "Email" },
  { id: "actions", label: "Actions", disableSorting: true },
];

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(0),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

function EmailsTable({ enquireKey, records, setRecords, openInPopup }) {
  const classes = useStyles();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const onDelete = (itemId, enquireKey) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteEmail(itemId, enquireKey);
    setRecords(getAllEmails(enquireKey));
    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });
  };

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells, filterFn);

  return (
    <>
      <Paper className={classes.pageContent}>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item) => (
              <TableRow key={item.id}>
                {/*<TableCell>{item.id}</TableCell>*/}
                <TableCell>{item.email}</TableCell>
                <TableCell>
                  <ActionButton
                    // color="primary"
                    onClick={() => {
                      openInPopup(item, enquireKey);
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </ActionButton>
                  <ActionButton
                    // color="secondary"
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: "Are you sure to delete this email?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          onDelete(item.id, enquireKey);
                        },
                      });
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </ActionButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}

export default EmailsTable;
