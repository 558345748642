import React from "react";
import EventsLists from "../components/event/List";
import { SideContent, MainContent } from "../layout/styles";
// import Share from "../components/common/share";

function Events() {
  return (
    <>
      <SideContent>
        {/*<Share />*/}
      </SideContent>
      <MainContent>
        {" "}
        <EventsLists />
      </MainContent>
    </>
  );
}

export default Events;
