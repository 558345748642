import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import SignUpStepperForm from "../components/auth/form/SignUpStepperForm";
import { SideContent, MainContent } from "../layout/styles";

export default function SignInSide() {
  return (
    <>
      <>
        <SideContent />
        <MainContent style={{ marginTop: "170px", height: "120vh" }}>
          <CssBaseline />
          <Container
            maxWidth="md"
            style={{ marginLeft: "0px", paddingLeft: "0px" }}
          >
            <Typography
              component="h1"
              variant="h3"
              align="left"
              style={{ marginBottom: "25px" }}
            >
              Register
            </Typography>
            <SignUpStepperForm />
          </Container>
        </MainContent>
      </>
    </>
  );
}
