import React, { createContext, useState } from "react";
import { useHistory } from "react-router-dom";

const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const history = useHistory();

  const token = localStorage.getItem("hasta-token");
  const userInfo = localStorage.getItem("hasta-userInfo");
  const expiresAt = localStorage.getItem("hasta-expiresAt");

  const [authState, setAuthState] = useState({
    token,
    expiresAt,
    userInfo: userInfo ? JSON.parse(userInfo) : {},
  });

  const setAuthInfo = ({ token, userInfo, expiresAt }) => {
    localStorage.setItem("hasta-token", token);
    localStorage.setItem("hasta-userInfo", JSON.stringify(userInfo));
    localStorage.setItem("hasta-expiresAt", expiresAt);

    setAuthState({
      token,
      userInfo,
      expiresAt,
    });
  };

  const logout = async () => {
    try {
      localStorage.removeItem("hasta-token");
      localStorage.removeItem("hasta-userInfo");
      localStorage.removeItem("hasta-expiresAt");
      setAuthState({});
      if (history) {
        history.push("/login");
      }
    } catch (onError) {
      console.log(onError);
    }
  };

  const isAdmin = () => {
    const { userInfo } = authState;
    if (userInfo) {
      return userInfo.isAdmin;
    }

    return null;
  };

  const isAuthenticated = () => {
    if (!authState.expiresAt) {
      return false;
    }
    return new Date() < new Date(authState.expiresAt * 1000);
  };

  const getAccessToken = () => {
    return localStorage.getItem("hasta-token");
  };

  const getUserId = () => {
    const userInfoItem = localStorage.getItem("hasta-userInfo");
    if (userInfoItem) {
      const user = JSON.parse(userInfoItem);
      return user.id;
    }

    return null;
  };

  return (
    <Provider
      value={{
        authState,
        setAuthState: (authInfo) => setAuthInfo(authInfo),
        logout,
        isAuthenticated,
        isAdmin,
        getAccessToken,
        getUserId,
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
