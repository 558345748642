import React, { useState } from "react";
import Input from "../common/Input";
import { useForm, Form } from "../useForm";

const initialFValues = {
  id: 1,
  numberOfPersons: 0,
};

export default function EventInvitationNumberOfPersonsForm({ enquireKey }) {
  const [numberOfPersons, setNumberOfPersons] = useState(
    localStorage.getItem(`${enquireKey}-numberOfPersons`)
      ? parseInt(localStorage.getItem(`${enquireKey}-numberOfPersons`), 10)
      : 1
  );

  const handleBlur = (event) => {
    localStorage.setItem(`${enquireKey}-numberOfPersons`, event.target.value);
    setNumberOfPersons(event.target.value);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("numberOfPersons" in fieldValues)
      temp.numberOfPersons = fieldValues.numberOfPersons
        ? ""
        : "This field is required.";
    if ("numberOfPersons" in fieldValues)
      temp.numberOfPersons =
        fieldValues.numberOfPersons > 0 ? "" : "Must be greater than 0";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x.toString() === "");
  };

  const { values, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  return (
    <Form>
      <Input
        name="numberOfPersons"
        label="Number of persons"
        type="number"
        defaultValue={numberOfPersons}
        onChange={handleInputChange}
        error={errors.numberOfPersons}
        onBlur={handleBlur}
        color="secondary"
        maxWidth
      />
    </Form>
  );
}
