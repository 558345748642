import React, { useState, useEffect, useContext } from "react";
import map from "lodash/map";
import Add from "images/add.svg";

import { InviteFormSidebar } from "components/invite/styles";
import {
  MediaBlock,
  MediaBlockImage,
  MediaBlockBody,
  Table,
} from "components/invite/styles";

import { Button } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import Popup from "./addEventTablePopup";
import AddEventTableForm from "./addEventTableForm";
import { useParams } from "react-router-dom";
import { HttpContext } from "context/HttpContext";
import TabPanel from "./tabPanel";
import { useSnackbar } from "notistack";
import { AuthContext } from "../../context/AuthContext";
// import filter from "lodash/filter"

function InviteSidebar() {
  const auth = useContext(AuthContext);
  const isAdmin = auth?.isAdmin() ?? false;

  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({
    items: [],
    status: "idle",
    error: null,
  });

  const [value, setValue] = useState(0);

  const {
    items,
    // status, error
  } = state;

  const [openPopup, setOpenPopup] = useState(false);
  const { authAxios } = useContext(HttpContext);

  const { id } = useParams();

  useEffect(() => {
    const getData = async () => {
      try {
        setState({
          ...state,
          status: "pending",
        });

        const { data } = await authAxios.get(`event/${id}/tables`);
        // const items = filter(data, (x) => x.canAddInvite);

        setState({
          ...state,
          items: data,
          status: "resolved",
        });
      } catch (onError) {
        console.log(onError);
        setState({
          ...state,
          error: "Something went wrong",
          status: "rejected",
        });
      }
    };

    getData();
  }, [authAxios, id]);

  const handleClickOpenPopup = () => {
    setOpenPopup(true);
  };

  function addItem(item) {
    const previousItems = state.items;
    const items = [item, ...previousItems];
    enqueueSnackbar("You successfully added new table", { variant: "success" });
    setState({
      ...state,
      items,
    });
  }

  function updateItem(data, type) {
    if (type === "add") {
      const { items } = state;
      let index = items.findIndex((x) => x.id === data.tableId);
      let oldItem = items[index];
      let oldUsed = oldItem.used;
      let newItem = { ...oldItem, used: oldUsed + 1 };
      items[index] = { ...newItem };

      setState({
        ...state,
        items,
      });
    }

    if (type === "remove") {
      const { items } = state;
      let index = items.findIndex((x) => x.id === data.tableId);
      let oldItem = items[index];
      let oldUsed = oldItem.used;
      let newItem = { ...oldItem, used: oldUsed - 1 };
      items[index] = { ...newItem };

      setState({
        ...state,
        items,
      });
    }
  }

  const handleTabs = (event, newValue) => {
    setValue(newValue);
  };

  function CustomTab(props) {
    return <Tab component="div" {...props} />;
  }

  // function TabPanel(props) {
  //   const { children, value, index } = props;
  //   return <div>{value === index && <div>{children}</div>}</div>;
  // }

  return (
    <>
      <InviteFormSidebar>
        {isAdmin && (
          <MediaBlock>
            <Button onClick={handleClickOpenPopup}>
              <MediaBlockImage>
                <img src={Add} alt={""} />
              </MediaBlockImage>
              <MediaBlockBody>
                <span>Add table</span>
              </MediaBlockBody>
            </Button>
          </MediaBlock>
        )}
        {items.length > 0 && (
          <>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleTabs}
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
            >
              {map(items, ({ id: tableId, name, used, capacity }) => (
                <CustomTab
                  key={tableId}
                  label={
                    <React.Fragment>
                      <Table>
                        <span>{name}</span>
                        <small>
                          (private table for {capacity} -{" "}
                          <span style={{ color: "#0062DF" }}>
                            {" "}
                            {capacity - used} unoccupied seats
                          </span>
                          )
                        </small>
                      </Table>
                    </React.Fragment>
                  }
                />
              ))}
            </Tabs>
          </>
        )}
      </InviteFormSidebar>
      {items.length > 0 && (
        <TabPanel value={value} table={items[value]} updateItem={updateItem} />
      )}
      <Popup
        title="To create table for event, please enter table name and capacity."
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <AddEventTableForm setOpenPopup={setOpenPopup} addItem={addItem} />
      </Popup>
    </>
  );
}

export default InviteSidebar;
