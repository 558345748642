import React, { useContext, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {
  Box,
  // Card,
  // CardContent,
  CircularProgress,
  // Step,
  // StepLabel,
  // Stepper,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

import { Field, Form, Formik } from "formik";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import * as yup from "yup";
import "yup-phone";

import { HttpContext } from "../../../context/HttpContext";
// import { useHistory } from "react-router-dom";

// const sleep = (time) => new Promise((acc) => setTimeout(acc, time));

// import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { Redirect } from "react-router-dom";
// import { REGEX_VALID_PHONE } from "../../../util";
import LoginCodeActivationPopup from "../popup/LoginCodeActivation";
import ScrollToTop from "../../scrollToTop";
import ActivateAccountForm from "./ActivateAccountForm";
import AutomaticSignInForm from "./AutomaticSignInForm";
import {
  getAllergies,
  getDiets,
  getVegetables,
  getInterests,
  getMeats,
} from "../../../util";

export function FormikStep({ children }) {
  return <>{children}</>;
}

export function FormikStepper({ children, ...props }) {
  const childrenArray = React.Children.toArray(children);
  const step = props.step ? props.step : 0;
  // const setStep = props.setStep
  const currentChild = childrenArray[step];
  const [
    ,
    // completed,
    setCompleted,
  ] = useState(false);

  function isLastStep() {
    return step === childrenArray.length - 1;
  }

  function isPenultimateStep() {
    return step === childrenArray.length - 2;
  }

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isPenultimateStep()) {
          await props.onSubmit(values, helpers);
          props.setStep((s) => s + 1);

          setCompleted(true);
        } else {
          props.setStep((s) => s + 1);

          // the next line was not covered in the youtube video
          //
          // If you have multiple fields on the same step
          // we will see they show the validation error all at the same time after the first step!
          //
          // If you want to keep that behaviour, then, comment the next line :)
          // If you want the second/third/fourth/etc steps with the same behaviour
          //    as the first step regarding validation errors, then the next line is for you! =)
          //
          // In the example of the video, it doesn't make any difference, because we only
          //    have one field with validation in the second step :)
          helpers.setTouched({});
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form autoComplete="off">
          {/*<Stepper alternativeLabel activeStep={step}>*/}
          {/*  {childrenArray.map((child, index) => (*/}
          {/*    <Step*/}
          {/*      key={child.props.label}*/}
          {/*      completed={step > index || completed}*/}
          {/*    >*/}
          {/*      /!*<StepLabel>{child.props.label}</StepLabel>*!/*/}
          {/*    </Step>*/}
          {/*  ))}*/}
          {/*</Stepper>*/}

          {currentChild}

          <Grid container spacing={2}>
            {step > 0 && !isLastStep() ? (
              <Grid item>
                <Button
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  onClick={() => props.setStep((s) => s - 1)}
                >
                  Back
                </Button>
              </Grid>
            ) : null}
            {!isLastStep() && (
              <Grid item>
                <Button
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  type="submit"
                  endIcon={isSubmitting ? null : <ArrowRightAltIcon />}
                >
                  {isSubmitting
                    ? "Submitting"
                    : isPenultimateStep()
                    ? "Register"
                    : "Next step"}
                </Button>
              </Grid>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default function SignUpStepperForm() {
  const { enqueueSnackbar } = useSnackbar();
  const [step, setStep] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [credential, setCredential] = useState({
    email: null,
    password: null,
  });
  const { publicAxios } = useContext(HttpContext);

  // const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  // const phoneRegExp = /^\+?[1-9]\d{1,14}$/
  // '/^[a-zA-Z0-9]*$/'
  const validationSchema = yup.object({
    firstName: yup
      .string("Enter your first name")
      .required("First name is required"),
    lastName: yup
      .string("Enter your last name")
      .required("Last name is required"),
    username: yup
      .string("Enter your username")
      .min(6, "Username should be of minimum 6 characters length")
      .matches(
        /^[a-zA-Z0-9]*$/,
        "Username must contains only letters and/or numbers"
      )
      .required("Username is required"),
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .min(6, "Password should be of minimum 6 characters length")
      .required("Password is required"),
    repeatPassword: yup
      .string("Enter your password")
      .required("Repeat your password")
      .oneOf([yup.ref("password")], "Passwords do not match"),
    mobile: yup
      .string("Enter your mobile")
      // .matches(
      //   REGEX_VALID_PHONE,
      //   "Mobile number is not valid"
      // )
      // .matches(phoneRegExp, 'Mobile number is not valid')
      .required("Mobile is required"),
    bookingRef: yup.string("Enter your booking ref"),
  });

  if (redirectToLogin) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <>
        <FormikStepper
          step={step}
          setStep={setStep}
          initialValues={{
            firstName: "",
            lastName: "",
            username: "",
            email: "",
            password: "",
            repeatPassword: "",
            mobile: "",
            bookingRef: "",

            vegan: false,
            vegetarian: false,
            glutenFree: false,
            milkFree: false,
            eggFree: false,

            chicken: false,
            beef: false,
            pork: false,
            fish: false,

            onion: false,
            potato: false,
            garlic: false,
            tomato: false,
            eggplant: false,
            spinach: false,

            nuts: false,
            dairy: false,
            shellfish: false,
            eggs: false,
            soy: false,
            gluten: false,
            coeliac: false,

            music: false,
            theatre: false,
            sport: false,
            food: false,
          }}
          onSubmit={async (values, actions) => {
            publicAxios
              .post(`register`, {
                "form[email]": values.email,
                "form[username]": values.username,
                "form[password]": values.password,
                "form[firstName]": values.firstName,
                "form[lastName]": values.lastName,
                "form[phone]": values.mobile,
                "form[bookingRef]": values.bookingRef,
                "form[allergies]": getAllergies(values),
                "form[diet]": getDiets(values),
                "form[vegetables]": getVegetables(values),
                "form[interests]": getInterests(values),
                "form[meat]": getMeats(values),
              })
              .then((res) => {
                const { data } = res;
                if (data.status === "success") {
                  enqueueSnackbar(
                    "Please first enter the activation code you received in the confirmation email.",
                    {
                      variant: "success",
                    }
                  );

                  setCredential({
                    email: values.email,
                    password: values.password,
                  });
                }
              })
              .catch((onError) => {
                console.log(onError)
                const { data } = onError.response;
                if (data) {
                  if (data.status === "error") {
                    if (data.status_msg === "Invalid phone format") {
                      actions.setFieldError("mobile", "Invalid phone format");
                    }
                    if (data.status_msg === "Email already exist") {
                      actions.setFieldError("email", "Email is already taken");
                    }
                    if (
                      data.status_msg ===
                      "Your username can contain only letters and/or numbers"
                    ) {
                      actions.setFieldError(
                        "username",
                        "Your username can contain only letters and/or numbers"
                      );
                    }
                    if (
                      data.status_msg ===
                      "Your username must be at least 6 characters long"
                    ) {
                      actions.setFieldError(
                        "username",
                        "Your username must be at least 6 characters long"
                      );
                    }
                    if (data.status_msg === "Username already exist") {
                      actions.setFieldError(
                        "username",
                        "Username already exist"
                      );
                    }
                    if (data.status_msg === "Phone already exist") {
                      actions.setFieldError("mobile", "Phone already exist");
                    }
                    setStep(0);
                  }
                }
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
          }}
        >
          <FormikStep label="Personal Data" validationSchema={validationSchema}>
            <Box paddingBottom={2}>
              <p style={{ marginTop: "0" }}>First name</p>
              <Field
                variant="filled"
                fullWidth
                name="firstName"
                component={TextField}
                placeholder="First Name"
                autoComplete="given-name"
              />
            </Box>
            <Box paddingBottom={2}>
              <p style={{ marginTop: "0" }}>Last name</p>
              <Field
                fullWidth
                variant="filled"
                name="lastName"
                component={TextField}
                placeholder="Last Name"
                autoComplete="family-name"
              />
            </Box>
            <Box paddingBottom={2}>
              <p style={{ marginTop: "0" }}>Username</p>
              <Field
                fullWidth
                variant="filled"
                name="username"
                component={TextField}
                placeholder="User Name"
                autoComplete="family-name"
              />
            </Box>
            <Box paddingBottom={2}>
              <p style={{ marginTop: "0" }}>Email</p>
              <Field
                fullWidth
                variant="filled"
                name="email"
                component={TextField}
                placeholder="email@example.com"
                autoComplete="email"
              />
            </Box>
            <Box paddingBottom={2}>
              <p style={{ marginTop: "0" }}>Password</p>
              <Field
                fullWidth
                variant="filled"
                name="password"
                type="password"
                component={TextField}
                placeholder="•••••••••"
              />
            </Box>
            <Box paddingBottom={2}>
              <p style={{ marginTop: "0" }}>Repeat password</p>
              <Field
                variant="filled"
                fullWidth
                name="repeatPassword"
                type="password"
                component={TextField}
                placeholder="•••••••••"
                autoComplete="repeat-password"
              />
            </Box>
            <Box paddingBottom={2}>
              <p style={{ marginTop: "0" }}>Mobile</p>
              <Field
                variant="filled"
                fullWidth
                name="mobile"
                component={TextField}
                placeholder="0800 555 5555"
              />
            </Box>
            <Box paddingBottom={2}>
              <p style={{ marginTop: "0" }}>Booking ref</p>
              <Field
                variant="filled"
                fullWidth
                name="bookingRef"
                component={TextField}
                placeholder="ABC123"
              />
            </Box>
          </FormikStep>
          <FormikStep label="Allergies">
            <ScrollToTop />
            <Box paddingBottom={2}>
              <Typography paddingBottom={4}>
                Please select your dietary preferences
              </Typography>
            </Box>
            <Typography>Diet:</Typography>
            <Box paddingBottom={2}>
              <Field
                name="vegan"
                type="checkbox"
                component={CheckboxWithLabel}
                Label={{ label: "Vegan" }}
              />
              <Field
                name="vegetarian"
                type="checkbox"
                component={CheckboxWithLabel}
                Label={{ label: "Vegetarian" }}
              />
              <Field
                name="glutenFree"
                type="checkbox"
                component={CheckboxWithLabel}
                Label={{ label: "Gluten-free" }}
              />
              <Field
                name="milkFree"
                type="checkbox"
                component={CheckboxWithLabel}
                Label={{ label: "Milk-free" }}
              />
              <Field
                name="eggFree"
                type="checkbox"
                component={CheckboxWithLabel}
                Label={{ label: "Egg-free" }}
              />
            </Box>
            <Typography>Meat:</Typography>
            <Box paddingBottom={2}>
              <Field
                name="chicken"
                type="checkbox"
                component={CheckboxWithLabel}
                Label={{ label: "Chicken" }}
              />
              <Field
                name="beef"
                type="checkbox"
                component={CheckboxWithLabel}
                Label={{ label: "Beef" }}
              />
              <Field
                name="pork"
                type="checkbox"
                component={CheckboxWithLabel}
                Label={{ label: "Pork" }}
              />
              <Field
                name="fish"
                type="checkbox"
                component={CheckboxWithLabel}
                Label={{ label: "Fish" }}
              />
            </Box>
            <Typography>Vegetables:</Typography>
            <Box paddingBottom={2}>
              <Field
                name="onion"
                type="checkbox"
                component={CheckboxWithLabel}
                Label={{ label: "Onion" }}
              />
              <Field
                name="potato"
                type="checkbox"
                component={CheckboxWithLabel}
                Label={{ label: "Potato" }}
              />
              <Field
                name="garlic"
                type="checkbox"
                component={CheckboxWithLabel}
                Label={{ label: "Garlic" }}
              />
              <Field
                name="tomato"
                type="checkbox"
                component={CheckboxWithLabel}
                Label={{ label: "Tomato" }}
              />
              <Field
                name="eggplant"
                type="checkbox"
                component={CheckboxWithLabel}
                Label={{ label: "Eggplant" }}
              />
              <Field
                name="spinach"
                type="checkbox"
                component={CheckboxWithLabel}
                Label={{ label: "Spinach" }}
              />
            </Box>
            <Typography>Please select your allergies below</Typography>
            <Box paddingBottom={2}>
              <Field
                name="nuts"
                type="checkbox"
                component={CheckboxWithLabel}
                Label={{ label: "Nuts" }}
              />
              <Field
                name="dairy"
                type="checkbox"
                component={CheckboxWithLabel}
                Label={{ label: "Dairy" }}
              />
              <Field
                name="shellfish"
                type="checkbox"
                component={CheckboxWithLabel}
                Label={{ label: "Shellfish" }}
              />
              <Field
                name="eggs"
                type="checkbox"
                component={CheckboxWithLabel}
                Label={{ label: "Eggs" }}
              />
              <Field
                name="soy"
                type="checkbox"
                component={CheckboxWithLabel}
                Label={{ label: "Soy" }}
              />
              <Field
                name="gluten"
                type="checkbox"
                component={CheckboxWithLabel}
                Label={{ label: "Gluten" }}
              />
              <Field
                name="coeliac"
                type="checkbox"
                component={CheckboxWithLabel}
                Label={{ label: "Coeliac" }}
              />
            </Box>
            <Typography>Please select your interests</Typography>

            <Box paddingBottom={2}>
              <Field
                name="music"
                type="checkbox"
                component={CheckboxWithLabel}
                Label={{ label: "Music" }}
              />
              <Field
                name="theatre"
                type="checkbox"
                component={CheckboxWithLabel}
                Label={{ label: "Theatre" }}
              />
              <Field
                name="sport"
                type="checkbox"
                component={CheckboxWithLabel}
                Label={{ label: "Sport" }}
              />
              <Field
                name="food"
                type="checkbox"
                component={CheckboxWithLabel}
                Label={{ label: "Food" }}
              />
            </Box>
          </FormikStep>
          <FormikStep label="Registration submitting Info">
            <Box paddingBottom={2}>
              {credential.password && credential.email ? (
                <>
                  <AutomaticSignInForm
                    setOpenPopup={setOpenPopup}
                    email={credential.email}
                    password={credential.password}
                  />
                  <LoginCodeActivationPopup
                    title="Please enter code and activate your account"
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    redirectToLogin={redirectToLogin}
                    setRedirectToLogin={setRedirectToLogin}
                  >
                    <ActivateAccountForm />
                  </LoginCodeActivationPopup>
                </>
              ) : (
                <CircularProgress />
              )}
            </Box>
          </FormikStep>
        </FormikStepper>
      </>
    </>
  );
}
