import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import { AuthContext } from "../../context/AuthContext";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LogoutButton = () => {
  const history = useHistory();
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const handleClick = () => {
    history.push("/login");
    auth && auth.logout();
  };
  return (
    <Button
      onClick={handleClick}
      variant="contained"
      color="primary"
      className={classes.submit}
      margin="normal"
      size="small"
    >
      Logout
    </Button>
  );
};

export default LogoutButton;
