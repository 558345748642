import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Slider from "./slider";
import FaqAccordion from "./faqAcordions";
import Price from "./priceTable";
import moment from "moment";
// // import EventMap from './map'

function TabPanel(props) {
  const { children, value, index, event, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} event={event}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    borderBottom: "1px solid #8080807a",
  },
  buttonTab: {
    minWidth: "0",
    textTransform: "none",
    fontSize: "18px",
  },
}));

export default function SimpleTabs({ event, isEnquired, enquireOpen }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const showFAQ = false;
  const showPrice = false;

  return (
    <div className={classes.root}>
      <>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab
            active
            className={classes.buttonTab}
            label="About"
            {...a11yProps(0)}
          />
          {/*<Tab*/}
          {/*  active*/}
          {/*  className={classes.buttonTab}*/}
          {/*  label="Description"*/}
          {/*  {...a11yProps(1)}*/}
          {/*/>*/}
          {/*<Tab*/}
          {/*  disabled*/}
          {/*  icon={*/}
          {/*    <div style={{ display: "flex", justifyContent: "space-around" }}>*/}
          {/*      <img*/}
          {/*        src={Male}*/}
          {/*        style={{ marginRight: "5px" }}*/}
          {/*        alt="tabs-male"*/}
          {/*      />{" "}*/}
          {/*      <span> / </span>{" "}*/}
          {/*      <img*/}
          {/*        src={Female}*/}
          {/*        style={{ marginLeft: "5px" }}*/}
          {/*        alt="tabs-female"*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  }*/}
          />
        </Tabs>
      </>
      <TabPanel value={value} index={0}>
        <Typography dangerouslySetInnerHTML={{ __html: event.about }} />
        <Grid container style={{ marginTop: "37px" }}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h5">Date</Typography>
            <Typography color="secondary">
              {moment(event.startAt).format("dddd, Do MMMM y")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h5">Location</Typography>
            <Typography color="secondary">{event.location}</Typography>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "37px" }}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h5">Start</Typography>
            <Typography color="secondary">
              {moment(event.startAt).format("Ha")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h5">End</Typography>
            <Typography color="secondary">
              {moment(event.endAt).format("Ha")}
            </Typography>
          </Grid>
        </Grid>
        <Grid style={{ marginTop: "40px" }}>
          <Slider img={event.coverPhoto} />
        </Grid>
        {showFAQ && (
          <Grid style={{ marginTop: "40px", marginBottom: "60px" }}>
            <FaqAccordion />
          </Grid>
        )}
        {showPrice && (
          <Grid>
            <Price
              event={event}
              isEnquired={isEnquired}
              enquireOpen={enquireOpen}
            />
          </Grid>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {/* need provided key */}
        {/* <EventMap /> */}
        <p>map section</p>
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
    </div>
  );
}
