import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CloseIcon from "@material-ui/icons/Close";
import { Link as RouterLink } from "react-router-dom";
import LogoutButton from "../common/LogoutButton";
import Hamburger from "../../images/hamburger.svg";
import {
  // Link as RouterLink,
  useHistory,
} from "react-router-dom";

import { openInNewTab } from "../../util";

const useStyles = makeStyles({
  list: {
    //width: 250,
    padding: "40px 25px",
  },
  fullList: {
    width: "auto",
  },
  listItemText: {
    fontSize: "40px!important",
    lineHeight: "55px",
  },
});

export default function HeaderDrawer({ isAuthenticated }) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const history = useHistory();

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  function goto(path) {
    return () => {
      let liveHost = `https://hastaworld.com`;
      openInNewTab(`${liveHost}${path}`);
    };
  }

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem button key="Wellbeing" onClick={goto("/wellbeing")}>
          <ListItemText
            classes={{ primary: classes.listItemText }}
            primary="Wellbeing"
          />
        </ListItem>
        <ListItem button key="Events" onClick={() => history.push("/events")}>
          <ListItemText
            classes={{ primary: classes.listItemText }}
            primary="Events"
          />
        </ListItem>
        <ListItem button key="Partnerships" onClick={goto("/partnerships")}>
          <ListItemText
            classes={{ primary: classes.listItemText }}
            primary="Partnerships"
          />
        </ListItem>
        <ListItem
          button
          key="Our Ambassadors"
          onClick={goto("/our-ambassadors")}
        >
          <ListItemText
            classes={{ primary: classes.listItemText }}
            primary="Our Ambassadors"
          />
        </ListItem>
        <ListItem button key="News" onClick={goto("/news")}>
          <ListItemText
            classes={{ primary: classes.listItemText }}
            primary="News"
          />
        </ListItem>
        <ListItem button key="About Us" onClick={goto("/about-hastaworld")}>
          <ListItemText
            classes={{ primary: classes.listItemText }}
            primary="About Us"
          />
        </ListItem>
        <ListItem button key="Contact Us" onClick={goto("/contact-us")}>
          <ListItemText
            classes={{ primary: classes.listItemText }}
            primary="Contact Us"
          />
        </ListItem>

        <ListItem
          button
          style={{ paddingTop: "50px" }}
          key="Privacy Policy"
          onClick={goto("/privacy-policy")}
        >
          <ListItemText primary="Privacy Policy" />
        </ListItem>
        <ListItem button key="Cookie Policy" onClick={goto("/privacy-notice")}>
          <ListItemText primary="Cookie Policy" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            Menu <img src={Hamburger} alt="Img" width="30px" height="18px" style={{ marginLeft: "20px"}}/>
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <div
              style={{
                padding: "40px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {" "}
              <Grid item xs={2}>
                {!isAuthenticated ? (
                  <Grid container>
                    <Grid item xs>
                      <Button
                        component={RouterLink}
                        to="/login"
                        variant="contained"
                        color="primary"
                        margin="normal"
                        onClick={toggleDrawer(anchor, false)}
                        style={{ border: "1px solid white" }}
                      >
                        Login
                      </Button>
                      {/*<span style={{ paddingLeft: "10px" }}> or</span>*/}
                    </Grid>
                    <Grid item>
                      <Button
                        component={RouterLink}
                        to="/register"
                        variant="contained"
                        color="primary"
                        margin="normal"
                        onClick={toggleDrawer(anchor, false)}
                        style={{ border: "1px solid white" }}
                      >
                        Register
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <LogoutButton />
                )}
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Button
                  style={{ color: "white" }}
                  onClick={toggleDrawer(anchor, false)}
                >
                  Menu
                  <CloseIcon style={{ paddingLeft: "7px" }} />
                </Button>
              </Grid>
            </div>

            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
