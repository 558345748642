import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import * as PropTypes from "prop-types";
import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { HttpContext } from "../../../context/HttpContext";
import * as yup from "yup";
import { useFormik } from "formik";
import { AuthContext } from "../../../context/AuthContext";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    textTransform: "none",
  },
}));

function SignInForm({ setOpenPopup, location }) {
  const history = useHistory();
  const classes = useStyles();
  const authContext = useContext(AuthContext);
  const { publicAxios } = useContext(HttpContext);

  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .min(6, "Password should be of minimum 6 characters length")
      .required("Password is required"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      publicAxios
        .post(`login`, {
          "form[email]": values.email,
          "form[password]": values.password,
        })
        .then((res) => {
          const { data } = res;
          if (data.status === "success") {
            const { user, apiToken, expiresAt } = data.data;
            authContext.setAuthState({
              token: apiToken,
              userInfo: user,
              expiresAt,
            });

            const pathname = location?.state?.next?.pathname ?? "/events";
            if (pathname === "/logout") {
              history.push("/events");
            } else if (pathname !== "/events") {
              history.push(pathname);
            }
          }
        })
        .catch((onError) => {
          console.log(onError)
          if (onError.response) {
            const { data } = onError.response;
            // console.log(typeof onError)
            // console.log(onError.response)
            // console.log(data)
            // forEach(errors, (message, name) => {
            //   actions.setFieldError(name, message)
            // });
            if (data) {
              if (data.status === "error") {
                if (data.status_msg === "User does not exist") {
                  actions.setFieldError("email", "User does not exist");
                } else if (data.status_msg === "Bad credentials") {
                  actions.setFieldError("password", "Bad credentials");
                } else if (data.status_msg === "Please confirm your account") {
                  // actions.setFieldError(
                  //   'email',
                  //   'Please confirm your account',
                  // )
                  setOpenPopup(true);
                }
              }
            }
          }
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <p style={{ marginBottom: "0" }}>Email address</p>
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              placeholder="email@example.com"
              autoFocus
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <p style={{ margin: "0" }}>Password</p>
            <TextField
              margin="normal"
              required
              variant="filled"
              fullWidth
              id="password"
              type="password"
              name="password"
              autoComplete="current-password"
              placeholder="••••••••"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              defaultValue=""
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              endIcon={<ArrowRightAltIcon />}
            >
              Log in
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

SignInForm.propTypes = { classes: PropTypes.any };

export default SignInForm;
