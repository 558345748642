export const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export function hasOnlyLettersAndNumbers(str) {
  const pattern = new RegExp(/^[a-zA-Z0-9]*$/);

  return pattern.test(str);
}

export const REGEX_HAS_SYMBOL = /(\W)+/;
export const REGEX_HAS_AT_LEAST_ONE_NUMBER = /[0-9]/;
export const REGEX_HAS_CONTAINS_BOTH_LOWER_AND_UPPERCASE_LETTERS = /^(?=.*[a-z])(?=.*[A-Z]).+$/;
export const REGEX_VALID_PHONE = /^\+?[1-9]\d{1,14}$/;

export function getDiets(values) {
  const { vegan, vegetarian, glutenFree, milkFree, eggFree } = values;
  let data = "";

  if (vegan) {
    if (data === "") {
      data = "vegan";
    } else {
      data = data + ", vegan";
    }
  }
  if (vegetarian) {
    if (data === "") {
      data = "vegetarian";
    } else {
      data = data + ", vegetarian";
    }
  }
  if (glutenFree) {
    if (data === "") {
      data = "glutenFree";
    } else {
      data = data + ", glutenFree";
    }
  }
  if (milkFree) {
    if (data === "") {
      data = "milkFree";
    } else {
      data = data + ", milkFree";
    }
  }
  if (eggFree) {
    if (data === "") {
      data = "eggFree";
    } else {
      data = data + ", eggFree";
    }
  }
  if (data === "") {
    return null;
  }
  return data;
}

export function getMeats(values) {
  const { chicken, beef, pork, fish } = values;
  let data = "";

  if (chicken) {
    if (data === "") {
      data = "chicken";
    } else {
      data = data + ", chicken";
    }
  }
  if (beef) {
    if (data === "") {
      data = "beef";
    } else {
      data = data + ", beef";
    }
  }
  if (pork) {
    if (data === "") {
      data = "pork";
    } else {
      data = data + ", pork";
    }
  }
  if (fish) {
    if (data === "") {
      data = "fish";
    } else {
      data = data + ", fish";
    }
  }
  if (data === "") {
    return null;
  }
  return data;
}

export function getVegetables(values) {
  const { onion, potato, garlic, tomato, eggplant, spinach } = values;
  let data = "";

  if (onion) {
    if (data === "") {
      data = "onion";
    } else {
      data = data + ", onion";
    }
  }
  if (potato) {
    if (data === "") {
      data = "potato";
    } else {
      data = data + ", potato";
    }
  }
  if (garlic) {
    if (data === "") {
      data = "garlic";
    } else {
      data = data + ", garlic";
    }
  }
  if (tomato) {
    if (data === "") {
      data = "tomato";
    } else {
      data = data + ", tomato";
    }
  }
  if (eggplant) {
    if (data === "") {
      data = "eggplant";
    } else {
      data = data + ", eggplant";
    }
  }
  if (spinach) {
    if (data === "") {
      data = "spinach";
    } else {
      data = data + ", spinach";
    }
  }
  if (data === "") {
    return null;
  }
  return data;
}

export function getAllergies(values) {
  const { nuts, dairy, shellfish, eggs, soy, gluten, coeliac } = values;
  let data = "";

  if (nuts) {
    if (data === "") {
      data = "nuts";
    } else {
      data = data + ", nuts";
    }
  }
  if (dairy) {
    if (data === "") {
      data = "dairy";
    } else {
      data = data + ", dairy";
    }
  }
  if (shellfish) {
    if (data === "") {
      data = "shellfish";
    } else {
      data = data + ", shellfish";
    }
  }
  if (eggs) {
    if (data === "") {
      data = "eggs";
    } else {
      data = data + ", eggs";
    }
  }
  if (soy) {
    if (data === "") {
      data = "soy";
    } else {
      data = data + ", soy";
    }
  }
  if (gluten) {
    if (data === "") {
      data = "gluten";
    } else {
      data = data + ", gluten";
    }
  }
  if (coeliac) {
    if (data === "") {
      data = "coeliac";
    } else {
      data = data + ", coeliac";
    }
  }
  if (data === "") {
    return null;
  }
  return data;
}

export function getInterests(values) {
  const { music, theatre, sport, food } = values;
  let data = "";

  if (music) {
    if (data === "") {
      data = "music";
    } else {
      data = data + ", music";
    }
  }
  if (theatre) {
    if (data === "") {
      data = "theatre";
    } else {
      data = data + ", theatre";
    }
  }
  if (sport) {
    if (data === "") {
      data = "sport";
    } else {
      data = data + ", sport";
    }
  }
  if (food) {
    if (data === "") {
      data = "food";
    } else {
      data = data + ", food";
    }
  }
  if (data === "") {
    return null;
  }
  return data;
}

export function isFunction(value) {
  if (value) {
    if ("function" === typeof value) {
      return true;
    }
    if (value instanceof Function) {
      return true;
    }
    if (Object.prototype.toString.call(value) === "[object Function]") {
      return true;
    }
  }

  return false;
}