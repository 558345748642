import styled from "styled-components";

export const MediaBlockImage = styled.div``;
export const MediaBlockBody = styled.div``;

export const InviteFormHolder = styled.div`
  background-color: rgba(185, 185, 185, 0.2);
  padding: 30px 20px;
  margin-bottom: 20px;
`;

export const MediaBlock = styled.div`
  display: flex;
  align-items: center;

  ${MediaBlockImage} {
    cursor: pointer;
    padding: 12px 12px 0 20px;
  }
  ${MediaBlockBody} {
    span {
      display: block;
      font-size: 16px;
    }
    small {
      font-size: 12px;
    }
  }
`;

export const InviteFormSidebar = styled.div`
  position: absolute;
  left: -290px;
  top: 138px;

  ${MediaBlock} {
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-right: 0;
    padding: 20px 11px;
  }

  ${MediaBlockImage} {
    padding: 0;
    margin-right: 11px;
  }
`;

export const Table = styled.div`
  display: block;
  padding: 11px 5px 11px 11px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  span {
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 27px;
  }

  small {
    font-size: 12px;
    line-height: 20px;

    span {
      font-size: 12px;
      font-weight: 400;
      display: inline;
    }
  }
`;
