import React, {
  // lazy,
  Suspense,
  useContext,
} from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import { SnackbarProvider } from "notistack";

/* Styles */
import { ThemeProvider } from "@material-ui/styles";
import { theme } from "./styles/Theme";

/* Layouts */
import LayoutMain from "./layout/main";
// import LayoutAuth from "./layout/authLayout";

/* Pages */
import Events from "./pages/Events";
import Event from "./pages/Event";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import Invite from "./pages/Invite";

/* Contexts */
import { AuthContext, AuthProvider } from "./context/AuthContext";
import { HttpProvider } from "./context/HttpContext";
import Logout from "./pages/Logout"
// import Invite2 from './pages/Invite2'

/* Lazy load */
//const Events = lazy(() => import('./pages/Events'))
//const Event = lazy(() => import('./pages/Event'))
const LoadingFallback = () => <div>Loading...</div>;

const RouteUnauthenticated = ({
  component: Component,
  layout: Layout,
  themeColor,
  ...rest
}) => {
  const auth = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isAuthenticated() ? (
          <Redirect to="/events" />
        ) : (
          <Layout {...props} themeColor={themeColor}>
            <Component {...props} />
          </Layout>
        )
      }
    />
  );
};

const AuthenticatedRoute = ({
  component: Component,
  layout: Layout,
  ...rest
}) => {
  const auth = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isAuthenticated() ? (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { next: props.location },
            }}
          />
        )
      }
    />
  );
};

const AppRoutes = () => {
  return (
    <>
      <Suspense fallback={<LoadingFallback />}>
        <Switch>
          <RouteUnauthenticated
            layout={LayoutMain}
            component={SignIn}
            path="/login"
            themeColor="black"
          />
          <RouteUnauthenticated
            layout={LayoutMain}
            component={SignUp}
            path="/register"
            themeColor="black"
          />
          <AuthenticatedRoute
            layout={LayoutMain}
            component={Invite}
            path="/events/:id/invite/:tableId"
          />
          <AuthenticatedRoute
            layout={LayoutMain}
            component={Invite}
            path="/events/:id/invite"
          />

          <AuthenticatedRoute
            layout={LayoutMain}
            component={Event}
            path="/events/:id"
          />

          <AuthenticatedRoute
            layout={LayoutMain}
            component={Events}
            path="/events"
          />

          <AuthenticatedRoute
            layout={LayoutMain}
            component={Invite}
            path="/invite"
          />
          <AuthenticatedRoute
            layout={LayoutMain}
            component={Logout}
            exact path="/logout"
          />
          <AuthenticatedRoute
            layout={LayoutMain}
            component={Events}
            path="/"
          />
        </Switch>
      </Suspense>
    </>
  );
};

export const App = () => {
  return (
    <AuthProvider>
      <Router>
        <HttpProvider>
          <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={6}>
              <AppRoutes />
            </SnackbarProvider>
          </ThemeProvider>
        </HttpProvider>
      </Router>
    </AuthProvider>
  );
};
