import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Seller from "../../images/selller.svg";
import { FooterContainer } from "./style";
import MulticolorBottomLine from "../common/multicolorBottomLine";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="left">
      {"© "}
      <Link color="inherit" href="https://hastaworld.com/">
        hasta - All rights reserved.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles(() => ({
  // footer: {
  //   backgroundColor: theme.palette.background.paper,
  //   marginTop: theme.spacing(8),
  //   padding: theme.spacing(6, 0),
  // },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <FooterContainer>
        <div className="links">
          <Copyright />
          <Link
            href="https://hastaworld.com/privacy-policy/"
            color="textSecondary"
            variant="body2"
            underline="always"
            rel="noopener noreferrer"
            target="_blank"
          >
            Privacy Policy
          </Link>
          <Link
            href="https://hastaworld.com/privacy-notice/"
            color="textSecondary"
            variant="body2"
            underline="always"
            rel="noopener noreferrer"
            target="_blank"
          >
            Privacy Notice
          </Link>
        </div>
        <div className="contactLinks">
          <Typography color="textPrimary">+44 (0) 207 770 8090</Typography>
          <Typography color="textPrimary">hello@hastaworld.com</Typography>
          <img src={Seller} alt="seller" />
        </div>
        <MulticolorBottomLine />
      </FooterContainer>
    </footer>
  );
}
