import styled from "styled-components";
import Hand from "../images/hand.svg";
import HandBlack from "../images/handBlack.svg";

export const MainContent = styled.div``;
export const AuthContent = styled.div``;

export const MainLayout = styled.div`
  background: url(${(props) =>
      (props.themeColor === "black" && `${HandBlack}`) || `${Hand}`})
    no-repeat;
  background-position: 142px 529px;
  background-size: 530px;
  @media screen and (min-width: 960px) {
    display: grid;
    grid-template-columns: 25% 75%;
    //grid-template-rows: 7vw 1fr 7vw;
    grid-gap: 0 1em;
  }
  header,
  footer {
    grid-column: 1 / span 2;
  }
`;

export const SideContent = styled.div`
    position: relative;
    background-color: ${(props) =>
      props.isGrey ? "rgba(185, 185, 185, .2)" : ""};
    @media screen and (max-width: 960px) {
      display: block;
      width: 100%;
      height: 200px;
    }
  }
`;

export const AuthLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  bacground: white;
  ${AuthContent} {
    flex: 1;
    margin-top: 100px;
  }
`;
