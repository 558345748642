import React, { useContext, useEffect, useState } from "react";
import { HttpContext } from "../../context/HttpContext";
import InviteForm from "./inviteForm";
import map from "lodash/map";
import filter from "lodash/filter";
import InviteFilled from "components/invite/inviteFilled";
// import { List, Paper } from '@material-ui/core'

export default function TabPanel(props) {
  const { table, updateItem } = props;
  const { id, capacity, used, canAddInvite } = table;
  const { authAxios } = useContext(HttpContext);
  const [state, setState] = useState({
    items: [],
    status: "idle",
    error: null,
  });

  useEffect(() => {
    const getData = async () => {
      try {
        setState({
          ...state,
          status: "pending",
        });

        const { data } = await authAxios.get(`table/${id}/invitations`);
        setState({
          ...state,
          items: data,
          status: "resolved",
        });
      } catch (onError) {
        console.log(onError);
        setState({
          ...state,
          error: "Something went wrong",
          status: "rejected",
        });
      }
    };

    getData();
  }, [authAxios, id]);
  if (!table) {
    return <div>Loading</div>;
  }

  function addInvite(data) {
    const items = [...state.items, data];
    setState({
      ...state,
      items,
    });
    updateItem(data, "add");
  }

  function removeInvite(data) {
    let items = { ...state.items };
    // console.log(items);
    // return
    items = filter(items, (x) => x.id !== data.id);
    setState({
      ...state,
      items,
    });
    updateItem(data, "remove");
  }

  const {
    items,
    // status, error
  } = state;

  return (
    // <Paper style={{maxHeight: 990, overflow: 'auto', padding: 20}}>
    <div>
      {capacity > used && canAddInvite && (
        <InviteForm tableId={id} used={used} addInvite={addInvite} />
      )}

      {map(items, (item, index) => (
        <InviteFilled
          invitation={item}
          key={item.id}
          index={index}
          removeInvite={removeInvite}
        />
      ))}
    </div>

    // </Paper>
  );
}
