import React, { Component } from "react";
import PropTypes from "prop-types";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { MainLayout } from "./styles";

class LayoutMain extends Component {
  render() {
    const { children } = this.props;
    // console.log(this.props.themeColor);
    return (
      <MainLayout themeColor={this.props.themeColor}>
        <Header />
        {/* <SideContent>
          <Share />
        </SideContent>
        <MainContent>{children}</MainContent> */}
        {children}
        <Footer />
      </MainLayout>
    );
  }
}

LayoutMain.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
  pathname: PropTypes.string,
};

export default LayoutMain;
