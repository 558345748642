import React, { useContext } from "react";
import * as PropTypes from "prop-types";
import { useHistory } from "react-router";
import { Link as RouterLink } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { CircularProgress } from "@material-ui/core";

import { HttpContext } from "../../../context/HttpContext";
import { AuthContext } from "../../../context/AuthContext";
import * as yup from "yup";
import { useFormik } from "formik";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function ActivateAccountForm({ location }) {
  const history = useHistory();
  const classes = useStyles();
  const authContext = useContext(AuthContext);
  const { publicAxios } = useContext(HttpContext);

  const validationSchema = yup.object({
    code: yup
      .string("Enter your code")
      // .min(6, "Code should be of minimum 6 characters length")
      .required("Code is required"),
  });
  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      publicAxios
        .post(`verify-account`, {
          "form[code]": values.code,
        })
        .then((res) => {
          const { data } = res;
          if (data.status === "success") {
            const { user, apiToken, expiresAt } = data.data;
            authContext.setAuthState({
              token: apiToken,
              userInfo: user,
              expiresAt,
            });
            const pathname = location?.state?.next?.pathname ?? "/events";
            if (pathname === "/logout") {
              history.push("/events");
            } else if (pathname !== "/events") {
              history.push(pathname);
            }
          }
        })
        .catch((onError) => {
          console.log(onError)
          const { data } = onError.response;
          if (data) {
            if (data.status === "error") {
              if (data.status_msg === "Code is required") {
                actions.setFieldError("code", "Code is required");
              } else if (data.status_msg === "Code is not valid") {
                actions.setFieldError("code", "Code is not valid");
              }
            }
          }
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              variant="filled"
              required
              fullWidth
              id="code"
              label="Code"
              name="code"
              autoComplete="code"
              autoFocus
              value={formik.values.code}
              onChange={formik.handleChange}
              error={formik.touched.code && Boolean(formik.errors.code)}
              helperText={formik.touched.code && formik.errors.code}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              endIcon={<ArrowRightAltIcon />}
              disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
              startIcon={
                formik.isSubmitting ? <CircularProgress size="1rem" /> : null
              }
            >
              Activate
            </Button>
          </Grid>

          <Grid container>
            <Grid item xs>
              {/*<Link href="#" variant="body2">*/}
              {/*  Forgot password?*/}
              {/*</Link>*/}
            </Grid>
            <Grid item>
              <Link component={RouterLink} to="/register" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

ActivateAccountForm.propTypes = { classes: PropTypes.any };

export default ActivateAccountForm;
