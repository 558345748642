import React, { useContext } from 'react'

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import { InviteFormHolder } from "components/invite/styles";
import { HttpContext } from '../../context/HttpContext'
import { useSnackbar } from 'notistack'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'

function InviteFilled({ invitation, index, removeInvite }) {
  const { authAxios } = useContext(HttpContext);
  const { enqueueSnackbar } = useSnackbar();
  const {
    firstName,
    lastName,
    phone,
    email,
    canDelete,
    canResend,
    id,
    status,
    type,
  } = invitation;

  function handleDelete() {
    authAxios({
      url: `event/table/invitations/${id}`,
      method: "DELETE",
    })
      .then(() => {
        removeInvite(invitation);
        enqueueSnackbar("You successfully deleted the invitation", {
          variant: "success",
        });
      })
      .catch((onError) => {
        console.log(onError);
        enqueueSnackbar("Something went wrong", { variant: "error" });
      });
  }

  function handleResend() {
    authAxios({
      url: `event/table/invitations/${id}/resend`,
      method: "POST",
    })
      .then(() => {
        enqueueSnackbar("You successfully resent the invitation", {
          variant: "success",
        });
      })
      .catch((onError) => {
        console.log(onError);
        enqueueSnackbar("Something went wrong", { variant: "error" });
      });
  }

  // function handleEdit(){
  //   enqueueSnackbar("This feature is not done, for now!", {
  //     variant: "warning",
  //   });
  //   // authAxios({
  //   //   url: `event/table/invitations/${id}`,
  //   //   method: "PATCH",
  //   // })
  //   //   .then(() => {
  //   //     removeInvite(invitation);
  //   //     enqueueSnackbar("You successfully edited the invitation", {
  //   //       variant: "success",
  //   //     });
  //   //   })
  //   //   .catch(onError => {
  //   //     console.log(onError);
  //   //     enqueueSnackbar("Something went wrong", { variant: "error" });
  //   //   });
  //
  // }

  return (
    <InviteFormHolder>
      <Typography variant="h5">
        Guest {index + 1}{" "}
        {type?.value === 0 && <span style={{ fontSize: "18px" }}>(Host)</span>}
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <p style={{ fontSize: "18px" }}>First name</p>
          <p style={{ fontSize: "16px", color: "#0062DF" }}>{firstName}</p>
        </Grid>
        <Grid item md={4}>
          <p style={{ fontSize: "18px" }}>Last name</p>
          <p style={{ fontSize: "16px", color: "#0062DF" }}>{lastName}</p>
        </Grid>
        <Grid item md={4}>
          <p style={{ fontSize: "18px" }}>Phone number</p>
          <p style={{ fontSize: "16px", color: "#0062DF" }}>{phone}</p>
        </Grid>
        <Grid item md={4}>
          <p style={{ fontSize: "18px" }}>Email address</p>
          <p style={{ fontSize: "16px", color: "#0062DF" }}>{email}</p>
        </Grid>
      </Grid>
      {status && (
        <Grid container justifyContent="flex-start" alignItems="flex-start">
          <Grid item md={4}>
            <p style={{ fontSize: "18px" }}>Status</p>
            {status === "pending" && (
              <p style={{ fontSize: "16px", color: "#b2a300" }}>Pending</p>
            )}
            {status === "confirmed" && (
              <p style={{ fontSize: "16px", color: "#357a38" }}>Confirmed</p>
            )}
            {status === "declined" && (
              <p style={{ fontSize: "16px", color: "#ff1744" }}>Declined</p>
            )}
            {status === "idle" && (
              <p style={{ fontSize: "16px", color: "#b2a300" }}>Idle</p>
            )}
          </Grid>
        </Grid>
      )}

      <Grid
        container
        spacing={1}
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        {/*{ (status === 'pending' || status === 'confirmed') && canEdit && (*/}
        {/*<Grid item md={4}>*/}
        {/*  <Button variant="outlined" color="primary" fullWidth onClick={handleEdit}>*/}
        {/*    Edit invite*/}
        {/*  </Button>*/}
        {/*</Grid>*/}
        {/*)}*/}
        {canDelete && (
          <Grid item md={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleDelete}
            >
              Delete invite
            </Button>
          </Grid>
        )}
        {(status === "pending" || status === "idle") && canResend && (
          <Grid item md={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleResend}
              endIcon={<ArrowRightAltIcon />}
            >
              Resend invite
            </Button>
          </Grid>
        )}
      </Grid>
    </InviteFormHolder>
  );
}

export default InviteFilled;
