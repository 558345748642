import React from "react";
import { SideContent, MainContent } from "layout/styles";
import InviteSidebar from "components/invite/inviteSidebar";

import ScrollToTop from "components/scrollToTop";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

function Invite() {
  return (
    <>
      <SideContent isGrey={true}></SideContent>
      <MainContent style={{ marginTop: "170px", minHeight: "120vh" }}>
        <Container
          maxWidth="md"
          pt="50px"
          style={{ marginBottom: "10px", position: "relative", float: "left" }}
        >
          <Typography variant="h3">Invite your guests</Typography>
          <p style={{ fontSize: "18px" }}>
            You can invite up to 12 guests to this table. Please fill in all
            mandatory fields and then send invitations to your guests.
          </p>
          <InviteSidebar />
        </Container>
        <ScrollToTop />
      </MainContent>
    </>
  );
}

export default Invite;
